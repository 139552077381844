import type { ActionContext } from 'vuex'
import type { NoteState } from './state'
import type { RootState } from '~/store/state'

export default {
  resetState({ commit }: ActionContext<NoteState, RootState>): void {
    commit('resetState')
  },
  fetchData({ dispatch }: ActionContext<NoteState, RootState>): Promise<[void, void]> {
    return Promise.all([
      dispatch('fetchNotes'),
      dispatch('fetchNoteLabels'),
    ])
  },
  /**
   * Notes
   */
  fetchNotes({ commit }: ActionContext<NoteState, RootState>): Promise<void> {
    return $http
      .$get('/notes/')
      .then((data: Note[]) => commit('loadNotes', data))
      .catch((error: any) => console.error('fetchNotes', error))
  },
  createNote({ commit, dispatch, rootState }: ActionContext<NoteState, RootState>, data: Omit<Note, 'created_dts' | 'updated_dts'>): Promise<{ noteId: NoteId, actionId: ActionId } | void> {
    data.id = data.id || uuid4()
    const timestamp = new Date().toISOString()
    const note = {
      ...data,
      labels: data.labels || [],
      created_dts: timestamp,
      updated_dts: timestamp,
      synced: false,
    }
    // If an entry is submitted, populate the date from the entry,
    // otherwise generate the date and check if a matching entry exists.
    if (note.entry) {
      note.date = rootState.journal.entriesDict[note.entry].date
    }
    else {
      const activeDate = getActiveDateString(rootState.profileSettings?.day_ends_at)
      note.date = activeDate
      note.entry = rootState.journal.entries.find(entry => entry.date === activeDate)?.id
    }
    commit('createNote', note)
    if (note.entry) {
      commit('journal/addNoteToEntry', { entryId: note.entry, noteId: note.id }, { root: true })
    }
    // If there are labels assigned, add the Note to those Labels.
    note.labels.forEach(noteLabelId => commit('addNoteToLabel', { noteLabelId, noteId: data.id }))
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.NOTES,
        model: Models.NOTE,
        action_type: ActionTypes.CREATE,
        data: note,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ noteId: data.id, actionId: action.id })
    }
    else {
      return $http
        .$post('notes/', { body: note })
        .catch((error: any) => console.error('createIdea', error))
    }
  },
  updateNote({ commit, dispatch, rootState }: ActionContext<NoteState, RootState>, data: Pick<Note, 'id'> & Partial<Omit<Note, 'entry' | 'created_dts' | 'updated_dts'>>): Promise<{ actionId: ActionId } | void> {
    const note = {
      ...data,
      updated_dts: new Date(),
      synced: false,
    }
    commit('updateNote', note)
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.NOTES,
        model: Models.NOTE,
        action_type: ActionTypes.UPDATE,
        data: note,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$patch(`notes/${note.id}/`, { body: note })
        .catch((error: any) => console.error('updateNote', error))
    }
  },
  addEntryToNote({ commit, dispatch, rootState }: ActionContext<NoteState, RootState>, data: { noteId: NoteId, entryId: EntryId }): Promise<{ actionId: ActionId } | void> {
    const noteChanges = {
      id: data.noteId,
      entry: data.entryId,
    }
    commit('updateNote', noteChanges)
    commit('journal/addNoteToEntry', { entryId: data.entryId, noteId: data.noteId }, { root: true })
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.NOTES,
        model: Models.NOTE,
        action_type: ActionTypes.UPDATE,
        data: noteChanges,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$patch(`notes/${data.noteId}/`, { body: { entry: data.entryId } })
        .catch((error: any) => console.error('addEntryToNote', error))
    }
  },
  removeEntryFromNote({ commit, dispatch, rootState }: ActionContext<NoteState, RootState>, data: { noteId: NoteId, entryId: EntryId }): Promise<{ actionId: ActionId } | void> {
    const noteChanges = {
      id: data.noteId,
      entry: null,
    }
    commit('updateNote', noteChanges)
    commit('journal/removeNoteFromEntry', { entryId: data.entryId, noteId: data.noteId }, { root: true })
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.NOTES,
        model: Models.NOTE,
        action_type: ActionTypes.UPDATE,
        data: noteChanges,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$patch(`notes/${data.noteId}/`, { body: { entry: null } })
        .catch((error: any) => console.error('addEntryToNote', error))
    }
  },
  deleteNote({ state, commit, dispatch, rootState }: ActionContext<NoteState, RootState>, noteId: NoteId): Promise<{ actionId: ActionId } | void> {
    const note = state.notesDict[noteId]
    if (note.entry) {
      commit('journal/removeNoteFromEntry', { entryId: note.entry, noteId }, { root: true })
    }
    // Remove note from labels
    note.labels.forEach((noteLabelId) => {
      commit('updateNoteLabel', {
        id: noteLabelId,
        notes: state.noteLabelsDict[noteLabelId].notes.filter(nid => nid !== noteId),
      })
    })
    commit('deleteNote', noteId)
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.NOTES,
        model: Models.NOTE,
        action_type: ActionTypes.DELETE,
        data: {
          id: noteId,
          deleted_dts: new Date().toISOString(),
        },
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$delete(`notes/${noteId}/`)
        .catch((error: any) => console.error('deleteNote', error))
    }
  },
  addLabelToNote({ commit, dispatch, rootState }: ActionContext<NoteState, RootState>, data: { noteId: NoteId, noteLabelId: NoteLabelId }): Promise<{ actionId: ActionId } | void> {
    commit('addLabelToNote', data)
    commit('addNoteToLabel', data)
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.NOTES,
        model: Models.NOTE,
        action_type: ActionTypes.ACTION,
        action_route: 'add_label',
        data: {
          id: data.noteId,
          label: data.noteLabelId,
        },
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$put(`notes/${data.noteId}/add-label/`, { body: { label: data.noteLabelId } })
        .catch((error: any) => console.error('addLabelToNote', error))
    }
  },
  removeLabelFromNote({ commit, dispatch, rootState }: ActionContext<NoteState, RootState>, data: { noteId: NoteId, noteLabelId: NoteLabelId }): Promise<{ actionId: ActionId } | void> {
    commit('removeLabelFromNote', data)
    commit('removeNoteFromLabel', data)
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.NOTES,
        model: Models.NOTE,
        action_type: ActionTypes.ACTION,
        action_route: 'remove_label',
        data: {
          id: data.noteId,
          label: data.noteLabelId,
        },
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$put(`notes/${data.noteId}/remove-label/`, { body: { label: data.noteLabelId } })
        .catch((error: any) => console.error('removeLabelFromNote', error))
    }
  },
  /**
   * NoteLabels
   */
  fetchNoteLabels({ commit }: ActionContext<NoteState, RootState>): Promise<void> {
    return $http
      .$get('/notes/labels/')
      .then((data: NoteLabel[]) => commit('updateNoteLabels', data))
      .catch((error: any) => console.error('fetchNoteLabels', error))
  },
  createNoteLabel({ commit, dispatch, rootState }: ActionContext<NoteState, RootState>, data: Omit<NoteLabel, 'id' | 'created_dts' | 'updated_dts'>): Promise<{ noteLabelId: NoteLabelId, actionId: ActionId } | void> {
    // Use a deterministic UUID to avoid duplication
    const profileUid = rootState.profile?.uid
    if (profileUid) {
      const id = uuid5(data.name.toLowerCase(), profileUid)
      const timestamp = new Date().toISOString()
      const noteLabel = {
        id,
        ...data,
        notes: data.notes || [],
        created_dts: timestamp,
        updated_dts: timestamp,
        synced: false,
      }
      commit('createNoteLabel', noteLabel)
      if (rootState.profileSettings?.action_sync_enabled) {
        const action = generateAction({
          module: Modules.NOTES,
          model: Models.NOTE_LABEL,
          action_type: ActionTypes.CREATE,
          data: noteLabel,
        })
        commit('queueAction', action, { root: true })
        dispatch('pushActions', {}, { root: true })
        return Promise.resolve({ noteLabelId: id, actionId: action.id })
      }
      else {
        return $http
          .$post('notes/labels/', { body: noteLabel })
          .catch((error: any) => console.error('createNoteLabel', error))
      }
    }
    console.log('Could not save NoteLabel because the Profile UID is missing.')
    return Promise.resolve()
  },
  updateNoteLabel({ commit, dispatch, rootState }: ActionContext<NoteState, RootState>, data: Pick<NoteLabel, 'id'> & Partial<Omit<NoteLabel, 'created_dts' | 'updated_dts'>>): Promise<{ actionId: ActionId } | void> {
    const noteLabel = {
      ...data,
      updated_dts: new Date(),
      synced: false,
    }
    commit('updateNoteLabel', noteLabel)
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.NOTES,
        model: Models.NOTE_LABEL,
        action_type: ActionTypes.UPDATE,
        data: noteLabel,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$patch(`notes/labels/${noteLabel.id}/`, { body: noteLabel })
        .catch((error: any) => console.error('updateNoteLabel', error))
    }
  },
  deleteNoteLabel({ state, commit, dispatch, rootState }: ActionContext<NoteState, RootState>, noteLabelId: NoteLabelId): Promise<{ actionId: ActionId } | void> {
    const noteLabel = state.noteLabelsDict[noteLabelId]
    // Remove label from notes
    noteLabel.notes.forEach((noteId) => {
      const note = state.notesDict[noteId]
      if (note) {
        commit('updateNote', {
          id: noteId,
          labels: note.labels.filter(labelId => labelId !== noteLabelId),
        })
      }
    })
    commit('deleteNoteLabel', noteLabelId)
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.NOTES,
        model: Models.NOTE_LABEL,
        action_type: ActionTypes.DELETE,
        data: {
          id: noteLabelId,
          deleted_dts: new Date().toISOString(),
        },
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$delete(`notes/labels/${noteLabelId}/`)
        .catch((error: any) => console.error('deleteNoteLabel', error))
    }
  },
}
