/**
 * Tokens
 */

// Matches an alphanumeric character in any language.
// Note: '-' always needs to be the last item in a set, otherwise it depicts a range.
export const reAlhpaNumeric = '\\p{L}\\p{N}_-'
// Matches a word boundary in any language, including at the beginning and end
// of the string. Needs to exclude # and @ to make auto-tagging/mentioning work.
export const reWordBoundary = `([^#@${reAlhpaNumeric}]|^|$)`

/**
 * General patterns
 */

export const reEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const reLink = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))/gi

/**
 * Internal patterns
 */

export const reTagNew = new RegExp(`#([${reAlhpaNumeric}]+)`, 'giu')
export const rePersonNew = new RegExp(`@([${reAlhpaNumeric}]+)`, 'giu')
