import * as Sentry from '@sentry/vue'
import { version } from '~/package.json'

export default defineNuxtPlugin((nuxtApp) => {
  const release = `journalistic-pwa@${version}`
  const environment = import.meta.env.NODE_ENV

  if (environment !== 'development') {
    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: 'https://aa0f4cd5bbe34ca193b219566de402b4@o469931.ingest.sentry.io/5727327',
      release,
      environment,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation((nuxtApp as any).$router),
          tracingOrigins: ['localhost', 'pwa.journalisticapp.com', /^\//],
        }),
      ],
      tracesSampleRate: 0.05,
    })
  }
})
