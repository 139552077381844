import routerOptions0 from "/webapps/journalistic-pwa/frontend/node_modules/.pnpm/nuxt@3.10.0_eslint@8.56.0_rollup@3.29.4_sass@1.70.0_typescript@5.3.3_vite@5.0.12_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/webapps/journalistic-pwa/frontend/app/router.options.ts";
const configRouterOptions = {
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}