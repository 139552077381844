import type { FocusState } from './state'
import { defaultState } from './state'

export default {
  resetState(state: FocusState): void {
    Object.assign(state, defaultState)
  },
  populateGoalsDict(state: FocusState): void {
    state.goalsDict = listMapper(state.goals)
  },
  updateGoals(state: FocusState, data: Goal[]): void {
    data.sort((a: Goal, b: Goal) => a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0)
    state.goals = data
    state.goalsDict = listMapper(state.goals)
  },
  createGoal(state: FocusState, data: Goal): void {
    if (!state.goalsDict[data.id]) {
      // Find the correct index where to insert the new Goal based on its creation date.
      const goalCreatedDts = data.created_dts
      let targetIdx = 0
      for (const goal of state.goals) {
        if (goal.created_dts < goalCreatedDts) {
          break
        }
        targetIdx++
      }
      state.goalsDict[data.id] = data
      state.goals.splice(targetIdx, 0, data)
    }
  },
  updateGoal(state: FocusState, data: Pick<Goal, 'id'> & Partial<Goal>): void {
    const objId = data.id
    const idx = state.goals.findIndex(goal => goal.id === objId)
    const origObj = { ...state.goals[idx] }
    const origPriority = origObj.priority // Store explicitly, otherwise we'll only get an observer.
    const newObj = Object.assign(origObj, data)
    state.goals[idx] = newObj
    state.goalsDict[objId] = newObj
    // If the priority has changed, sort the goals list.
    if (newObj.priority !== origPriority) {
      state.goals.sort((a, b) => a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0)
    }
  },
  deleteGoal(state: FocusState, goalId: GoalId): void {
    const idx = state.goals.findIndex(obj => obj.id === goalId)
    state.goals.splice(idx, 1)
    delete state.goalsDict[goalId]
  },
}
