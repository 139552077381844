import type { ActionContext } from 'vuex'
import type { RootState } from '../state'
import type { PeopleState } from './state'

export default {
  resetState({ commit }: ActionContext<PeopleState, RootState>): void {
    commit('resetState')
  },
  fetchData({ dispatch }: ActionContext<PeopleState, RootState>): Promise<[void, void]> {
    return Promise.all([
      dispatch('fetchPeople'),
      dispatch('fetchPersonGroups'),
    ])
  },
  /**
   * People
   */
  fetchPeople({ commit }: ActionContext<PeopleState, RootState>): Promise<void> {
    return $http
      .$get('/people/')
      .then((people: Person[]) => commit('updatePeople', people))
      .catch((error: any) => console.error('fetchPeople', error))
  },
  updatePerson(
    { commit, dispatch, rootState }: ActionContext<PeopleState, RootState>,
    data: Pick<Person, 'id'> & Partial<Person>,
  ): Promise<{ actionId: ActionId } | void> {
    const person = {
      ...data,
      updated_dts: new Date().toISOString(),
      synced: false,
    }
    commit('updatePerson', person)
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.PEOPLE,
        model: Models.PERSON,
        action_type: ActionTypes.UPDATE,
        data: person,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$patch(`people/${person.id}/`, { body: person })
        .catch((error: any) => console.error('updatePerson', error))
    }
  },
  async deletePerson(
    { commit, dispatch, state, rootState }: ActionContext<PeopleState, RootState>,
    personId: PersonId,
  ): Promise<void> {
    const person = state.peopleDict[personId]
    const actions: Promise<any>[] = []
    // Remove root if the person has one.
    if (person.root) {
      const root = state.peopleDict[person.root]
      await dispatch('removeAliasFromPerson', {
        person: root,
        aliasId: person.id,
      })
    }
    // Remove aliases if the person has any.
    person.aliases.forEach((aliasId) => {
      actions.push(
        dispatch('removeAliasFromPerson', {
          person,
          aliasId,
        }),
      )
    })
    await Promise.all(actions)
    actions.splice(0)
    // Remove person from groups if there are any.
    person.groups.forEach((groupId) => {
      const personGroup = state.personGroupsDict[groupId]
      actions.push(
        dispatch('removePersonFromGroup', {
          personGroup,
          personId: person.id,
        }),
      )
    })
    await Promise.all(actions)
    actions.splice(0)
    person.bullets?.forEach((bulletId) => {
      const bullet = rootState.journal.bulletsDict[bulletId]
      const re = new RegExp(`${reWordBoundary}@(${person.name})${reWordBoundary}`, 'giu')
      const newText = bullet.text.replace(re, (_, boundBf, personName, boundAf) => {
        return `${boundBf}${personName}${boundAf}`
      })
      // Remove person from bullet.
      actions.push(
        dispatch('journal/updateBullet', { id: bullet.id, text: newText }, { root: true }),
      )
    })
    await Promise.all(actions)
    commit('deletePerson', person.id)
    if (!rootState.profileSettings?.action_sync_enabled) {
      dispatch('fetchPeople')
    }
  },
  addAliasToPerson(
    { commit, dispatch }: ActionContext<PeopleState, RootState>,
    data: { person: Person, aliasId: PersonId },
  ): Promise<{ actionId: ActionId } | void> {
    // Add alias to person
    commit('updatePerson', {
      id: data.person.id,
      aliases: [...data.person.aliases, data.aliasId],
    })
    dispatch('recompPersonProps', data.person.id)
    return dispatch('updatePerson', { id: data.aliasId, root: data.person.id })
  },
  removeAliasFromPerson(
    { commit, dispatch }: ActionContext<PeopleState, RootState>,
    data: { person: Person, aliasId: PersonId },
  ): Promise<{ actionId: ActionId } | void> {
    // Remove alias from person
    commit('updatePerson', {
      id: data.person.id,
      aliases: data.person.aliases.filter(aliasId => aliasId !== data.aliasId),
    })
    dispatch('recompPersonProps', data.person.id)
    return dispatch('updatePerson', { id: data.aliasId, root: null })
  },
  /**
   * Refresh a list of PersonGroup IDs from the backend,
   * aka. create, update or delete them in/from the store.
   * @param {int[]} payload - PersonGroup IDs that need to be refreshed.
   */
  refreshPeople({ state, commit }: ActionContext<PeopleState, RootState>, personIds: PersonId[]): Promise<void> {
    if (personIds.length) {
      return $http
        .$get(`people/?ids=${personIds.join(',')}`)
        .then((people: Person[]) => {
          people.forEach(person => commit(state.peopleDict[person.id] === undefined ? 'createPerson' : 'updatePerson', person))
          // IDs that didn't return an object were deleted by the backend, remove them.
          const updatedIds = people.map(obj => obj.id)
          const deletedIds = personIds.filter(personId => !updatedIds.includes(personId))
          deletedIds.forEach(personId => commit('deletePerson', personId))
        })
        .catch((error: Error) => console.error('refreshPeople', error))
    }
    return Promise.resolve()
  },
  recompPersonProps({ state, commit, dispatch, rootState }: ActionContext<PeopleState, RootState>, personId: PersonId): void {
    // Compute person properties.
    const person = state.peopleDict[personId]
    const bulletIds = person.bullets.concat(...person.aliases.map(aliasId => state.peopleDict[aliasId].bullets))
    const bullets = bulletIds.map(bulletId => rootState.journal.bulletsDict[bulletId])
    sortByField(bullets, 'date')
    commit('updatePerson', {
      id: personId,
      first_mention_date: bullets.length ? (bullets[0]?.date || null) : null,
      latest_mention_date: bullets.length ? (bullets[bullets.length - 1]?.date || null) : null,
      bullet_count: bullets.length,
    })
    // Update the person root props as well if the person has a root.
    if (person.root) {
      dispatch('recompPersonProps', person.root)
    }
    // Update the props of the person's groups if it has any.
    person.groups.forEach(personGroupId => dispatch('recompPersonGroupProps', personGroupId))
  },
  /**
   * PersonGroups
   */
  fetchPersonGroups({ commit }: ActionContext<PeopleState, RootState>): Promise<void> {
    return $http
      .$get('/people/groups/')
      .then((personGroups: PersonGroup[]) => commit('updatePersonGroups', personGroups))
      .catch((error: any) => console.error('fetchPersonGroups', error))
  },
  createPersonGroup(
    { commit, dispatch, rootState }: ActionContext<PeopleState, RootState>,
    data: Omit<PersonGroup, 'id' | 'created_dts' | 'updated_dts'>,
  ): Promise<{ personGroupId: PersonGroupId, actionId: ActionId } | void> {
    const id = uuid4()
    const timestamp = new Date().toISOString()
    const personGroup = {
      id,
      ...data,
      people: data.people || [],
      created_dts: timestamp,
      updated_dts: timestamp,
      synced: false,
    }
    commit('createPersonGroup', personGroup)
    personGroup.people.forEach((personId) => {
      commit('addGroupToPerson', { personId, personGroupId: personGroup.id })
    })
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.PEOPLE,
        model: Models.PERSON_GROUP,
        action_type: ActionTypes.CREATE,
        data: personGroup,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ personGroupId: id, actionId: action.id })
    }
    else {
      return $http
        .$post('people/groups/', { body: personGroup })
        .catch((error: any) => console.error('createPersonGroup', error))
    }
  },
  updatePersonGroup(
    { commit, dispatch, rootState }: ActionContext<PeopleState, RootState>,
    data: Pick<PersonGroup, 'id'> & Partial<Omit<PersonGroup, 'created_dts' | 'updated_dts'>>,
  ): Promise<{ actionId: ActionId } | void> {
    const personGroup = {
      ...data,
      updated_dts: new Date().toISOString(),
      synced: false,
    }
    commit('updatePersonGroup', personGroup)
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.PEOPLE,
        model: Models.PERSON_GROUP,
        action_type: ActionTypes.UPDATE,
        data: personGroup,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$patch(`people/groups/${personGroup.id}/`, { body: personGroup })
        .catch((error: any) => console.error('updatePersonGroup', error))
    }
  },
  deletePersonGroup(
    { state, commit, dispatch, rootState }: ActionContext<PeopleState, RootState>,
    personGroupId: PersonGroupId,
  ): Promise<{ actionId: ActionId } | void> {
    const personGroup = state.personGroupsDict[personGroupId]
    // Remove Group from its People
    personGroup.people.forEach((personId: PersonId) => {
      commit('removeGroupFromPerson', { personId, personGroupId })
    })
    commit('deletePersonGroup', personGroupId)
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.PEOPLE,
        model: Models.PERSON_GROUP,
        action_type: ActionTypes.DELETE,
        data: {
          id: personGroupId,
          deleted_dts: new Date().toISOString(),
        },
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$delete(`people/groups/${personGroupId}/`)
        .catch((error: any) => console.error('deletePersonGroup', error))
    }
  },
  addPersonToGroup(
    { commit, dispatch, rootState }: ActionContext<PeopleState, RootState>,
    data: { personId: PersonId, personGroupId: PersonGroupId },
  ): Promise<{ actionId: ActionId } | void> {
    commit('addPersonToGroup', data)
    commit('addGroupToPerson', data)
    if (rootState.profileSettings?.action_sync_enabled) {
      dispatch('recompPersonGroupProps', data.personGroupId)
      dispatch('recompPersonProps', data.personId)
      const action = generateAction({
        module: Modules.PEOPLE,
        model: Models.PERSON_GROUP,
        action_type: ActionTypes.ACTION,
        action_route: 'add_person',
        data: {
          id: data.personGroupId,
          person: data.personId,
        },
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$put(`people/groups/${data.personGroupId}/add-person/`, { body: { person: data.personId } })
        .then(() => {
          dispatch('refreshPeople', [data.personId])
          dispatch('refreshPersonGroups', [data.personGroupId])
        })
        .catch((error: any) => console.error('addPersonToGroup', error))
    }
  },
  removePersonFromGroup(
    { commit, dispatch, rootState }: ActionContext<PeopleState, RootState>,
    data: { personId: PersonId, personGroupId: PersonGroupId },
  ): Promise<{ actionId: ActionId } | void> {
    commit('removePersonFromGroup', data)
    commit('removeGroupFromPerson', data)
    if (rootState.profileSettings?.action_sync_enabled) {
      dispatch('recompPersonGroupProps', data.personGroupId)
      dispatch('recompPersonProps', data.personId)
      const action = generateAction({
        module: Modules.PEOPLE,
        model: Models.PERSON_GROUP,
        action_type: ActionTypes.ACTION,
        action_route: 'remove_person',
        data: {
          id: data.personGroupId,
          person: data.personId,
        },
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$put(`people/groups/${data.personGroupId}/remove-person/`, { body: { person: data.personId } })
        .then(() => {
          dispatch('refreshPeople', [data.personId])
          dispatch('refreshPersonGroups', [data.personGroupId])
        })
        .catch((error: any) => console.error('removePersonFromGroup', error))
    }
  },
  /**
   * Refresh a list of PersonGroup IDs from the backend,
   * aka. create, update or delete them in/from the store.
   * @param {int[]} payload - PersonGroup IDs that need to be refreshed.
   */
  refreshPersonGroups({ state, commit }: ActionContext<PeopleState, RootState>, personGroupIds: PersonGroupId[]): Promise<void> {
    if (personGroupIds.length) {
      return $http
        .$get(`people/groups/?ids=${personGroupIds.join(',')}`)
        .then((personGroups: PersonGroup[]) => {
          personGroups.forEach(personGroup => commit(state.personGroupsDict[personGroup.id] === undefined ? 'createPersonGroup' : 'updatePersonGroup', personGroup))
          // IDs that didn't return an object were deleted by the backend, remove them.
          const updatedIds = personGroups.map(obj => obj.id)
          const deletedIds = personGroupIds.filter(personGroupId => !updatedIds.includes(personGroupId))
          deletedIds.forEach(personGroupId => commit('deletePersonGroup', personGroupId))
        })
        .catch((error: Error) => console.error('refreshPersonGroups', error))
    }
    return Promise.resolve()
  },
  recompPersonGroupProps({ state, commit, rootState }: ActionContext<PeopleState, RootState>, personGroupId: PersonGroupId): void {
    // Compute PersonGroup properties.
    const personGroup = state.personGroupsDict[personGroupId]
    const people = personGroup.people.map(personId => state.peopleDict[personId])
    const bulletIds = ([] as BulletId[]).concat(...people.map(person => (
      person.bullets.concat(...person.aliases.map(aliasId => state.peopleDict[aliasId].bullets))
    )))
    const bullets = bulletIds.map(bulletId => rootState.journal.bulletsDict[bulletId])
    sortByField(bullets, 'date')
    commit('updatePersonGroup', {
      first_mention_date: bullets.length ? bullets[0].date : null,
      latest_mention_date: bullets.length ? bullets[bullets.length - 1].date : null,
      bullet_count: bullets.length,
    })
  },
}
