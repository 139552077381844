export default defineNuxtPlugin((nuxtApp) => {
  let stickyObserver: any

  nuxtApp.vueApp.directive('sticky', {
    created: (el: HTMLElement) => {
      // Note: this doesn't work, at least not with windicss.
      // You have to add the sticky class manually.
      el.classList.add('sticky')
    },
    mounted: (el: HTMLElement) => {
      stickyObserver = new IntersectionObserver(
        ([elem]) => {
          elem.target.setAttribute('data-stuck', (elem.intersectionRatio < 1).toString())
        },
        { threshold: [1] },
      )
      nextTick(() => stickyObserver.observe(el))
    },
    unmounted: () => {
      if (stickyObserver) {
        stickyObserver.disconnect()
      }
    },
  })
})
