import { addHours, differenceInDays, format, set } from 'date-fns'

export const specialDatesFixed: { [date: string]: string } = {
  '01-01': 'New Year\'s Day',
  '02-02': 'Groundhog Day',
  '02-14': 'Valentine\'s Day',
  '03-08': 'International Women\'s Day',
  '03-17': 'Saint Patrick\'s Day',
  '04-01': 'April Fool\'s Day',
  '06-19': 'Juneteenth',
  '07-04': 'Independence Day',
  '10-31': 'Halloween',
  '11-11': 'Veterans Day',
  '12-24': 'Christmas Eve',
  '12-25': 'Christmas Day',
  '12-26': 'Boxing Day',
  '12-31': 'New Year\'s Eve',
}

export const specialDatesVariable: { [year: number]: { [date: string]: string } } = {
  2019: {
    // Starts Nov 2019.
    '11-11': 'Veterans Day',
    '11-28': 'Thanksgiving', // (Fourth Thursday in November)
    '11-29': 'Black Friday',
    '12-22': 'Winter Solstice',
  },
  2020: {
    '01-20': 'Martin Luther King Jr. Day',
    '02-29': 'Leap Day',
    '03-20': 'Spring Equinox',
    '03-28': 'Earth Hour',
    '04-10': 'Good Friday',
    '04-12': 'Easter Sunday',
    '05-25': 'Memorial Day',
    '06-20': 'Summer Solstice',
    '09-07': 'Labor Day',
    '09-22': 'Autumn Equinox',
    '11-13': 'Friday the 13th',
    '11-26': 'Thanksgiving', // (Fourth Thursday in November)
    '11-27': 'Black Friday',
    '12-21': 'Winter Solstice',
  },
  2021: {
    '01-17': 'Martin Luther King Jr. Day',
    '03-20': 'Spring Equinox',
    '03-27': 'Earth Hour',
    '04-02': 'Good Friday',
    '04-04': 'Easter Sunday',
    '05-31': 'Memorial Day',
    '05-09': 'Mother\'s Day',
    '06-20': 'Father\'s Day',
    '06-21': 'Summer Solstice',
    '08-13': 'Friday the 13th',
    '09-06': 'Labor Day',
    '09-22': 'Autumn Equinox',
    '11-25': 'Thanksgiving', // (Fourth Thursday in November)
    '11-26': 'Black Friday',
    '12-21': 'Winter Solstice',
  },
  2022: {
    '01-17': 'Martin Luther King Jr. Day',
    '05-13': 'Friday the 13th',
    '03-20': 'Spring Equinox',
    '03-26': 'Earth Hour',
    '04-17': 'Easter Sunday',
    '05-08': 'Mother\'s Day',
    '05-30': 'Memorial Day',
    '06-19': 'Father\'s Day',
    '06-21': 'Summer Solstice',
    '09-05': 'Labor Day',
    '09-23': 'Autumn Equinox',
    '11-24': 'Thanksgiving', // (Fourth Thursday in November)
    '11-25': 'Black Friday',
    '12-21': 'Winter Solstice',
  },
  2023: {
    '01-16': 'Martin Luther King Jr. Day',
    '03-20': 'Spring Equinox',
    '04-07': 'Good Friday',
    '04-09': 'Easter Sunday',
    '05-14': 'Mother\'s Day',
    '05-29': 'Memorial Day',
    '06-18': 'Father\'s Day',
    '06-21': 'Summer Solstice',
    '09-04': 'Labor Day',
    '09-23': 'Autumn Equinox',
    '11-23': 'Thanksgiving', // (Fourth Thursday in November)
    '11-24': 'Black Friday',
    '12-21': 'Winter Solstice',
  },
  2024: {
    '01-15': 'Martin Luther King Jr. Day',
    '03-20': 'Spring Equinox',
    '03-29': 'Good Friday',
    '03-31': 'Easter Sunday',
    '05-12': 'Mother\'s Day',
    '05-27': 'Memorial Day',
    '06-16': 'Father\'s Day',
    '06-21': 'Summer Solstice',
    '09-02': 'Labor Day',
    '09-22': 'Autumn Equinox',
    '11-28': 'Thanksgiving', // (Fourth Thursday in November)
    '11-29': 'Black Friday',
    '12-21': 'Winter Solstice',
  },
}

// Mother's day
// Father's day

// Mexico
// - Cinco de Mayo
// - Day of the Dead

export const WEEKDAY_ABBRS: { [index: number]: string } = {
  0: 'S',
  1: 'M',
  2: 'T',
  3: 'W',
  4: 'T',
  5: 'F',
  6: 'S',
}

export const WEEKDAY_LABELS: { [index: number]: string } = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
}

export function initDate(dateStr?: DateString): Date | undefined {
  return dateStr ? new Date(dateStr) : undefined
}

export function getActiveDate(dayEndsAtHour?: number | undefined, date?: Date): Date {
  const now = date || new Date()
  return (dayEndsAtHour && (now.getHours() < dayEndsAtHour))
    ? addHours(now, -24)
    : now
}

export function getActiveDateString(dayEndsAtHour?: number | undefined, date?: Date): DateString {
  return format(getActiveDate(dayEndsAtHour, date), 'yyyy-MM-dd')
}

export function getDateString(date: Date): DateString {
  return format(date, 'yyyy-MM-dd')
}

export function getFormattedDate(date: Date): string {
  return date
    ? format(date, `MMM d${date.getFullYear() === new Date().getFullYear() ? '' : ', yyyy'}`)
    : ''
}

export function getFormattedTime(date: Date): string {
  return date
    ? format(date, 'h:mm a')
    : ''
}

export function getFormattedDateTime(date: Date): string {
  return `${getFormattedDate(date)}, ${getFormattedTime(date)}`
}

export function getDateDisplay(dateStr: string): string {
  if (dateStr) {
    const date = new Date(`${dateStr}T12:00:00`)
    return format(date, `EEE, MMM d${date.getFullYear() === new Date().getFullYear() ? '' : ' yyyy'}`)
  }
  return ''
}

export function getTimeDisplay(datetimeStr: string): string {
  if (datetimeStr) {
    const date = new Date(datetimeStr)
    return format(date, 'h:mm a')
  }
  return ''
}

export function getDaysAgo(date: Date | undefined): string {
  if (date) {
    const daysSince = differenceInDays(new Date(), date)
    if (daysSince === 0)
      return 'Today'
    else if (daysSince === 1)
      return 'Yesterday'
    return `${daysSince} days ago`
  }
  return ''
}

export function getDateTag(date: Date | undefined): string {
  return date
    ? format(date, `EEE, MMM d${date.getFullYear() === new Date().getFullYear() ? '' : ', yyyy'}`)
    : ''
}

export function getWeekStartDate(): Date {
  const now = new Date()
  const weekday = now.getDay()
  const daysFromMonday = weekday ? weekday - 1 : 6 // This is weird, bc Sunday is 0.
  return set(addHours(now, -daysFromMonday * 24), {
    hours: 0,
    minutes: 0,
    seconds: 0,
  })
}
