import type { ActionContext } from 'vuex'
import type { JournalState } from './state'
import type { RootState } from '~/store/state'

export default {
  resetState({ commit }: ActionContext<JournalState, RootState>): void {
    commit('resetState')
  },
  fetchData({ dispatch }: ActionContext<JournalState, RootState>): Promise<[void, void]> {
    return Promise.all([
      dispatch('fetchEntries'),
      dispatch('fetchBullets'),
    ])
  },
  fetchEntries({ commit }: ActionContext<JournalState, RootState>): Promise<void> {
    return $http
      .$get('/journal/entries/')
      .then((entries: Entry[]) => commit('updateEntries', entries))
      .catch((error: any) => console.error('fetchEntries', error))
  },
  fetchBullets({ commit }: ActionContext<JournalState, RootState>): Promise<void> {
    return $http
      .$get('/journal/bullets/')
      .then((bullets: Bullet[]) => commit('updateBullets', bullets))
      .catch((error: any) => console.error('fetchBullets', error))
  },
  /**
   * Entries
   */
  updateEntries({ commit }: ActionContext<JournalState, RootState>, entries: Entry[]): void {
    commit('updateEntries', entries)
  },
  createEntry(
    { commit, dispatch, rootState }: ActionContext<JournalState, RootState>,
    data: Omit<Entry, 'id' | 'created_dts' | 'updated_dts'>,
  ): Promise<Entry | undefined | void> {
    if (rootState.profile) {
      // Generate a deterministic UUID based on date and profile UID.
      const id = uuid5(data.date, rootState.profile.uid)
      const timestamp = new Date().toISOString()
      const entry = {
        id,
        ...data,
        created_dts: timestamp,
        updated_dts: timestamp,
      }
      return $http
        .$post('journal/entries/', { body: entry })
        .then((savedEntry: Entry) => {
          commit('createEntry', savedEntry)
          // Link to existing objects if there are any.
          const entryDate = savedEntry.date
          if (!data.notes) {
            const notes = rootState.notes.notes.filter(note => !note.entry && note.date === entryDate)
            notes.forEach(note => dispatch('notes/addEntryToNote', { noteId: note.id, entryId: savedEntry.id }, { root: true }))
          }
          if (!data.gems) {
            const gems = rootState.gems.gems.filter(gem => !gem.entry && gem.date === entryDate)
            gems.forEach(gem => dispatch('gems/addEntryToGem', { gemId: gem.id, entryId: savedEntry.id }, { root: true }))
          }
          if (!data.ideas) {
            const ideas = rootState.ideas.ideas.filter(idea => !idea.entry && idea.date === entryDate)
            ideas.forEach(idea => dispatch('ideas/addEntryToIdea', { ideaId: idea.id, entryId: savedEntry.id }, { root: true }))
          }
          return savedEntry
        })
        .catch((error: any) => {
          console.error('createEntry', error)
          return undefined
        })
    }
    return Promise.resolve()
  },
  createEntryNew(
    { commit, dispatch, rootState }: ActionContext<JournalState, RootState>,
    data: Omit<Entry, 'id' | 'created_dts' | 'updated_dts'>,
  ): Promise<{ entryId: EntryId, actionId: ActionId } | void> {
    if (rootState.profile) {
      // Generate a deterministic UUID based on date and profile UID.
      const id = uuid5(data.date, rootState.profile.uid)
      const timestamp = new Date().toISOString()
      const entry = {
        id,
        ...data,
        images: [],
        dreams: [],
        bullets: [],
        notes: [],
        gems: [],
        ideas: [],
        created_dts: timestamp,
        updated_dts: timestamp,
      }
      const action = generateAction({
        module: Modules.JOURNAL,
        model: Models.ENTRY,
        action_type: ActionTypes.CREATE,
        data: entry,
      })
      commit('createEntry', entry)
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      // Link to existing objects if there are any.
      const entryDate = entry.date
      if (!data.notes) {
        const notes = rootState.notes.notes.filter(note => !note.entry && note.date === entryDate)
        notes.forEach(note => dispatch('notes/addEntryToNote', { noteId: note.id, entryId: id }, { root: true }))
      }
      if (!data.gems) {
        const gems = rootState.gems.gems.filter(gem => !gem.entry && gem.date === entryDate)
        gems.forEach(gem => dispatch('gems/addEntryToGem', { gemId: gem.id, entryId: id }, { root: true }))
      }
      if (!data.ideas) {
        const ideas = rootState.ideas.ideas.filter(idea => !idea.entry && idea.date === entryDate)
        ideas.forEach(idea => dispatch('ideas/addEntryToIdea', { ideaId: idea.id, entryId: id }, { root: true }))
      }
      return Promise.resolve({ entryId: id, actionId: action.id })
    }
    return Promise.resolve()
  },
  updateEntry({ commit }: ActionContext<JournalState, RootState>, entry: Pick<Entry, 'id'> & Omit<Partial<Entry>, 'date'>): void {
    commit('updateEntry', entry)
  },
  moveEntry({ commit, dispatch, rootState, state }: ActionContext<JournalState, RootState>, data: { entryId: EntryId, date: DateString }): Entry | void {
    if (rootState.profile) {
      // Get the entry.
      const entry = state.entriesDict[data.entryId]
      const dreams = entry.dreams.slice()
      const bullets = entry.bullets.slice()
      const images = entry.images.slice()
      const notes = entry.notes.slice()
      const gems = entry.gems.slice()
      const ideas = entry.ideas.slice()

      // Create a new entry with the target date.
      const newEntryId = uuid5(data.date, rootState.profile.uid)
      const timestamp = (new Date()).toISOString()
      const newEntry = {
        id: newEntryId,
        date: data.date,
        dreams,
        bullets,
        images,
        notes,
        gems,
        ideas,
        created_dts: entry.created_dts,
        updated_dts: timestamp,
        synced: false,
      }
      commit('createEntry', newEntry)

      // Move all dreams, bullets and items to the new entry.
      dreams.forEach(dreamId => commit('dreams/updateDream', { id: dreamId, entry: newEntryId }, { root: true }))
      bullets.forEach(dreamId => commit('journal/updateBullet', { id: dreamId, entry: newEntryId }, { root: true }))
      notes.forEach(noteId => commit('notes/updateNote', { id: noteId, entry: newEntryId }, { root: true }))
      gems.forEach(gemId => commit('gems/updateGem', { id: gemId, entry: newEntryId }, { root: true }))
      ideas.forEach(ideaId => commit('ideas/updateIdea', { id: ideaId, entry: newEntryId }, { root: true }))

      // Delete the entry.
      commit('deleteEntry', data.entryId)

      if (rootState.profileSettings?.action_sync_enabled) {
        const action = generateAction({
          module: Modules.JOURNAL,
          model: Models.ENTRY,
          action_type: ActionTypes.ACTION,
          action_route: 'move',
          data: {
            id: data.entryId,
            date: data.date,
          },
        })
        commit('queueAction', action, { root: true })
        dispatch('pushActions', {}, { root: true })
      }
      else {
        $http
          .$put(`journal/entries/${data.entryId}/move/`, { body: { date: data.date } })
          .catch((error: any) => console.error('moveEntry', error))
      }
      return newEntry as Entry
    }
  },
  deleteEntry({ state, commit, dispatch }: ActionContext<JournalState, RootState>, entryId: EntryId): Promise<void> {
    const entry = Object.assign({}, state.entriesDict[entryId])
    // Remove entry from its items.
    entry.notes.forEach(noteId => commit('notes/updateNote', { id: noteId, entry: null }, { root: true }))
    entry.gems.forEach(gemId => commit('gems/updateGem', { id: gemId, entry: null }, { root: true }))
    entry.ideas.forEach(ideaId => commit('ideas/updateIdea', { id: ideaId, entry: null }, { root: true }))
    commit('deleteEntry', entryId)
    entry.bullets.forEach(bulletId => commit('deleteBullet', bulletId))
    return $http
      .$delete(`journal/entries/${entryId}/`)
      .then(() => {
        // Refresh only after the response arrives to make sure that all
        // updates in the backend have already happened...
        dispatch('tags/fetchTags', {}, { root: true })
        dispatch('people/fetchPeople', {}, { root: true })
      })
      .catch((error: Error) => {
        console.error('deleteEntry', error)
        commit('createEntry', entry) // restore (test this!)
      })
  },
  async deleteEntryNew(
    { state, commit, dispatch }: ActionContext<JournalState, RootState>,
    entryId: EntryId,
  ): Promise<void> {
    const entry = state.entriesDict[entryId]
    // Remove entry from its items.
    entry.notes.forEach(noteId => commit('notes/updateNote', { id: noteId, entry: null }, { root: true }))
    entry.gems.forEach(gemId => commit('gems/updateGem', { id: gemId, entry: null }, { root: true }))
    entry.ideas.forEach(ideaId => commit('ideas/updateIdea', { id: ideaId, entry: null }, { root: true }))
    // Delete all dreams and bullets first and then the entry.
    // The entry deletion has to wait for the dream- and bullet deletion actions are complete
    // because they need the entry for execution.
    await Promise.all([
      ...entry.dreams.map(dreamId => dispatch('deleteDream', dreamId)),
      ...entry.bullets.map(bulletId => dispatch('deleteBulletNew', bulletId)),
    ])
    commit('deleteEntry', entryId)
    const action = generateAction({
      module: Modules.JOURNAL,
      model: Models.ENTRY,
      action_type: ActionTypes.DELETE,
      data: {
        id: entryId,
        deleted_dts: new Date().toISOString(),
      },
    })
    commit('queueAction', action, { root: true })
    dispatch('pushActions', {}, { root: true })
  },
  deleteImageFromEntry({ commit }: ActionContext<JournalState, RootState>, payload: { entryId: EntryId, imageId: ImageId }): void {
    commit('deleteImageFromEntry', payload)
    $http
      .$put(`journal/entries/${payload.entryId}/delete-image/`, { body: { image_id: payload.imageId } })
      .catch((error: Error) => console.error('deleteImageFromEntry', error))
  },
  /**
   * Bullets
   */
  createBullet({ commit, dispatch, state }: ActionContext<JournalState, RootState>, data: Omit<Bullet, 'id' | 'created_dts' | 'updated_dts'>): Promise<Bullet | void> {
    console.time('createBullet action')
    // Calculate word count.
    data.word_count = getWordCount(data.text)
    const id = uuid4()
    const timestamp = new Date().toISOString()
    const entry = state.entriesDict[data.entry]
    if (entry) {
      const position = entry.bullets.length
        ? Math.max(...entry.bullets.reduce((list: number[], bulletId) => {
          const bullet = state.bulletsDict[bulletId]
          if (bullet)
            list.push(bullet.position)
          return list
        }, [])) + 10
        : 10
      const bullet = {
        id,
        ...data,
        position,
        created_dts: timestamp,
        updated_dts: timestamp,
      }
      // NOTE: this one needs to wait for a backend response to have access to the ID.
      const res = $http
        .$post('journal/bullets/', { body: bullet })
        .then((newBullet: Bullet) => {
          commit('createBullet', newBullet)
          commit('addBulletToEntry', { entryId: newBullet.entry, bulletId: newBullet.id })
          dispatch('tags/fetchTags', {}, { root: true })
          dispatch('people/fetchPeople', {}, { root: true })
          return bullet
        })
        .catch((error: Error) => console.error('createBullet', error))
      console.timeEnd('createBullet action')
      return res
    }
    console.timeEnd('createBullet action')
    return Promise.resolve()
  },
  createBulletNew(
    { commit, dispatch, rootState, state }: ActionContext<JournalState, RootState>,
    data: Omit<Bullet, 'id' | 'tags' | 'people' | 'created_dts' | 'updated_dts'>,
  ): Promise<{ bulletId: BulletId, actionId: ActionId } | void> {
    if (rootState.profile) {
      console.time('[a] createBulletNew')
      console.time('uuid4')
      const id = uuid4()
      console.timeEnd('uuid4')
      const timestamp = new Date().toISOString()
      const date = timestamp.slice(0, 10)
      console.time('retrieve entry')
      const entry = state.entriesDict[data.entry]
      console.timeEnd('retrieve entry')
      // Calculate derived properties.
      console.time('word count')
      data.word_count = getWordCount(data.text)
      console.timeEnd('word count')
      console.time('position')
      data.position = entry.bullets.length
        ? Math.max(...entry.bullets.reduce((list: number[], bulletId) => {
          const bullet = state.bulletsDict[bulletId]
          if (bullet)
            list.push(bullet.position)
          return list
        }, [])) + 10
        : 10
      console.timeEnd('position')
      const bullet: Bullet = {
        id,
        ...data,
        tags: [], // Populate after new tags were created.
        people: [], // Populate after new people were created.
        created_dts: timestamp,
        updated_dts: timestamp,
      }
      commit('createBullet', bullet)
      commit('addBulletToEntry', { entryId: entry.id, bulletId: id })
      // Extract tags and people, create them if they do not exist,
      // or otherwise update their derived properties.
      console.time('parse')
      const { tags, people } = parseText(data.text, rootState.profile.uid)
      console.timeEnd('parse')
      console.time('tags')
      for (const tag of tags) {
        if (!rootState.tags.tagsDict[tag.id]) {
          commit('tags/createTag', {
            id: tag.id,
            name: tag.name,
            bullet_count: 1,
            bullets: [id],
            aliases: [],
            groups: [],
            first_mention_date: date,
            latest_mention_date: date,
          }, { root: true })
          // For now we don't need to push aditional actions
          // because the backend will also parse the bullet and create missing tags.
          // But later on, replace the commit with a dispatch and handle
          // everything in the frontend.
        }
        else {
          commit('tags/addBulletToTag', { tagId: tag.id, bulletId: id }, { root: true })
          dispatch('tags/recompTagProps', tag.id, { root: true })
        }
        commit('addTagToBullet', { bulletId: id, tagId: tag.id })
      }
      console.timeEnd('tags')
      console.time('people')
      for (const person of people) {
        if (!rootState.people.peopleDict[person.id]) {
          commit('people/createPerson', {
            id: person.id,
            name: person.name,
            bullet_count: 1,
            bullets: [id],
            aliases: [],
            groups: [],
            first_mention_date: date,
            latest_mention_date: date,
          }, { root: true })
          // For now we don't need to push aditional actions
          // because the backend will also parse the bullet and create missing tags.
          // But later on, replace the commit with a dispatch and handle
          // everything in the frontend.
        }
        else {
          commit('people/addBulletToPerson', { personId: person.id, bulletId: id }, { root: true })
          dispatch('people/recompPersonProps', person.id, { root: true })
        }
      }
      console.timeEnd('people')
      const action = generateAction({
        module: Modules.JOURNAL,
        model: Models.BULLET,
        action_type: ActionTypes.CREATE,
        data: bullet,
      })
      console.time('queue and push actions')
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      console.timeEnd('queue and push actions')
      console.timeEnd('[a] createBulletNew')
      return Promise.resolve({ bulletId: id, actionId: action.id })
    }
    return Promise.resolve()
  },
  updateBullet({ commit }: ActionContext<JournalState, RootState>, data: Pick<Bullet, 'id'> & Partial<Omit<Bullet, 'created_dts' | 'updated_dts'>>): Promise<Bullet | void> {
    console.time('updateBullet action')
    const bullet = {
      ...data,
      updated_dts: new Date(),
    }
    if ('text' in data) {
      bullet.word_count = getWordCount(data.text as string)
    }
    commit('updateBullet', data)
    const res = $http
      .$patch(`journal/bullets/${data.id}/`, { body: data })
      .then((bullet: Bullet) => {
        // Update bullet again in case tags or people changed
        commit('updateBullet', bullet)
        return bullet
      })
      .catch((error: Error) => console.error('updateBullet', error))
    console.timeEnd('updateBullet action')
    return res
  },
  updateBulletNew(
    { commit, dispatch, rootState, state }: ActionContext<JournalState, RootState>,
    data: Pick<Bullet, 'id'> & Partial<Omit<Bullet, 'tags' | 'people' | 'created_dts' | 'updated_dts'>>,
  ): Promise<{ actionId: ActionId } | void> {
    if (rootState.profile) {
      console.time('updateBulletNew action')
      const bullet = {
        ...data,
        updated_dts: new Date().toISOString(),
        synced: false,
      }
      if ('text' in data) {
        bullet.word_count = getWordCount(data.text as string)
        const { tags, people } = parseText(data.text as string, rootState.profile.uid)
        // Handle added/removed tags.
        const originalTagIds = state.bulletsDict[data.id].tags
        const newTagIds = tags.map(tag => tag.id)
        const addedTags = tags.filter(tag => !originalTagIds.includes(tag.id))
        const removedTagIds = originalTagIds.filter(tagId => !newTagIds.includes(tagId))
        for (const tag of addedTags) {
          if (!rootState.tags.tagsDict[tag.id]) {
            commit('tags/createTag', {
              id: tag.id,
              name: tag.name,
              bullet_count: 1,
              bullets: [bullet.id],
              aliases: [],
              groups: [],
              first_mention_date: bullet.date,
              latest_mention_date: bullet.date,
            }, { root: true })
            // For now we don't need to push aditional actions
            // because the backend will also parse the bullet and create missing tags.
            // But later on, replace the commit with a dispatch and handle
            // everything in the frontend.
          }
          else {
            commit('tags/addBulletToTag', { tagId: tag.id, bulletId: bullet.id }, { root: true })
            dispatch('tags/recompTagProps', tag.id, { root: true })
          }
          commit('addTagToBullet', { bulletId: bullet.id, tagId: tag.id })
        }
        for (const tagId of removedTagIds) {
          commit('removeTagFromBullet', { bulletId: bullet.id, tagId })
          commit('tags/removeBulletFromTag', { tagId, bulletId: bullet.id }, { root: true })
          if (rootState.tags.tagsDict[tagId].bullets.length) {
            dispatch('recomptTagProps', tagId)
          }
          else {
            dispatch('deleteTag', tagId)
          }
        }
        // Handle added/removed mentions.
        const originalPersonIds = state.bulletsDict[data.id].people
        const newPersonIds = people.map(person => person.id)
        const addedPeople = people.filter(person => !originalPersonIds.includes(person.id))
        const removedPersonIds = originalPersonIds.filter(personId => !newPersonIds.includes(personId))
        for (const person of addedPeople) {
          if (!rootState.people.peopleDict[person.id]) {
            commit('people/createPerson', {
              id: person.id,
              name: person.name,
              bullet_count: 1,
              bullets: [bullet.id],
              aliases: [],
              groups: [],
              first_mention_date: bullet.date,
              latest_mention_date: bullet.date,
            }, { root: true })
            // For now we don't need to push aditional actions
            // because the backend will also parse the bullet and create missing people.
            // But later on, replace the commit with a dispatch and handle
            // everything in the frontend.
          }
          else {
            commit('people/addBulletToPerson', { personId: person.id, bulletId: bullet.id }, { root: true })
            dispatch('people/recompPersonProps', person.id, { root: true })
          }
          commit('addPersonToBullet', { bulletId: bullet.id, personId: person.id })
        }
        for (const personId of removedPersonIds) {
          commit('removePersonFromBullet', { bulletId: bullet.id, personId })
          commit('people/removeBulletFromPerson', { personId, bulletId: bullet.id }, { root: true })
          if (rootState.people.peopleDict[personId].bullets.length) {
            dispatch('recompPersonProps', personId)
          }
          else {
            dispatch('people/deletePerson', personId)
          }
        }
      }
      commit('updateBullet', bullet)
      const action = generateAction({
        module: Modules.JOURNAL,
        model: Models.BULLET,
        action_type: ActionTypes.UPDATE,
        data: bullet,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      console.timeEnd('updateBulletNew action')
      return Promise.resolve({ actionId: action.id })
    }
    return Promise.resolve()
  },
  deleteBullet({ state, commit, dispatch }: ActionContext<JournalState, RootState>, bulletId: BulletId): Promise<void> {
    const bullet = state.bulletsDict[bulletId]
    const entry = state.entriesDict[bullet.entry]
    const tagCount = bullet.tags.length
    const personCount = bullet.people.length
    // Delete entry if the bullet is the last one and if there are no dreams.
    const deleteEntry = entry.bullets.length === 1 && entry.bullets[0] === bulletId && entry.dreams.length === 0
    // Make sure the bullet exists (there were some related errors in Sentry).
    if (bullet) {
      commit('removeBulletFromEntry', { entryId: entry.id, bulletId })
      commit('deleteBullet', bulletId)
      if (deleteEntry) {
        dispatch('deleteEntry', entry.id)
      }
      return $http
        .$delete(`journal/bullets/${bulletId}/`)
        .then(() => {
          if (!deleteEntry) {
            // Entry delete already refetches tags and people,
            // so no need to do it again.
            if (tagCount) {
              dispatch('tags/fetchTags', {}, { root: true })
            }
            if (personCount) {
              dispatch('people/fetchPeople', {}, { root: true })
            }
          }
        })
        .catch((error: Error) => console.error('deleteBullet', error))
    }
    return Promise.resolve()
  },
  deleteBulletNew(
    { state, commit, dispatch, rootState }: ActionContext<JournalState, RootState>,
    bulletId: BulletId,
  ): Promise<{ actionId: ActionId } | void> {
    const bullet = state.bulletsDict[bulletId]
    // Make sure the bullet exists (there were some related errors in Sentry).
    if (bullet) {
      const entry = state.entriesDict[bullet.entry]
      const tagIds = [...bullet.tags]
      const personIds = [...bullet.people]
      // First, remove bullet from its entry, then delete it.
      commit('removeBulletFromEntry', { entryId: entry.id, bulletId })
      // Remove bullet from tags and people and refresh them.
      tagIds.forEach((tagId) => {
        commit('tags/removeBulletFromTag', { tagId, bulletId }, { root: true })
        if (!rootState.tags.tagsDict[tagId].bullets.length) {
          dispatch('tags/deleteTag', tagId, { root: true })
        }
        else {
          dispatch('tags/recompTagProps', tagId, { root: true })
        }
      })
      personIds.forEach((personId) => {
        commit('people/removeBulletFromPerson', { personId, bulletId }, { root: true })
        if (!rootState.people.peopleDict[personId].bullets.length) {
          dispatch('people/deletePerson', personId, { root: true })
        }
        else {
          dispatch('people/recompPersonProps', personId, { root: true })
        }
      })
      commit('deleteBullet', bulletId)
      // If there are no more bullets left on the entry, delete it.
      // if (entry.bullets.length === 0) {
      //   dispatch('deleteEntry', entry.id)
      // }
      const action = generateAction({
        module: Modules.JOURNAL,
        model: Models.BULLET,
        action_type: ActionTypes.DELETE,
        data: {
          id: bulletId,
          deleted_dts: new Date().toISOString(),
        },
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    return Promise.resolve()
  },
  moveBullet(
    { commit, dispatch, rootState, state }: ActionContext<JournalState, RootState>,
    data: { entryId: EntryId, bulletId: BulletId, oldIdx: number, newIdx: number },
  ): Promise<{ actionId: ActionId } | void> {
    // Update bullet list of `Entry`.
    const entryId = data.entryId
    const bulletId = data.bulletId
    const origBulletList = [...state.entriesDict[entryId].bullets]
    const newBulletList = [...origBulletList]
    const newIdx = data.newIdx
    moveElement(newBulletList, data.oldIdx, newIdx)
    // Calculate new bullet position and update `Bullet`.
    let newPosVal
    if (newIdx === 0) {
      // First position.
      newPosVal = state.bulletsDict[newBulletList[1]].position - 10
    }
    else if (newIdx < newBulletList.length - 1) {
      // Not last position.
      newPosVal = (state.bulletsDict[newBulletList[newIdx + 1]].position
      + state.bulletsDict[newBulletList[newIdx - 1]].position) / 2
    }
    else {
      // Last position.
      newPosVal = state.bulletsDict[newBulletList[newBulletList.length - 2]].position + 10
    }
    commit('updateBullet', { id: bulletId, position: newPosVal })
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.JOURNAL,
        model: Models.BULLET,
        action_type: ActionTypes.UPDATE,
        data: {
          id: bulletId,
          position: newPosVal,
        },
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$patch(`/journal/bullets/${bulletId}/`, { body: { position: newPosVal } })
        .catch((error: Error) => console.error('moveBullet', error))
    }
  },
}
