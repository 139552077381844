import revive_payload_client_WLCqDx0mJK from "/webapps/journalistic-pwa/frontend/node_modules/.pnpm/nuxt@3.10.0_eslint@8.56.0_rollup@3.29.4_sass@1.70.0_typescript@5.3.3_vite@5.0.12_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_tfve8oM9m4 from "/webapps/journalistic-pwa/frontend/node_modules/.pnpm/nuxt@3.10.0_eslint@8.56.0_rollup@3.29.4_sass@1.70.0_typescript@5.3.3_vite@5.0.12_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_t42dM1Z050 from "/webapps/journalistic-pwa/frontend/node_modules/.pnpm/nuxt@3.10.0_eslint@8.56.0_rollup@3.29.4_sass@1.70.0_typescript@5.3.3_vite@5.0.12_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_cQGCIsICzZ from "/webapps/journalistic-pwa/frontend/node_modules/.pnpm/nuxt@3.10.0_eslint@8.56.0_rollup@3.29.4_sass@1.70.0_typescript@5.3.3_vite@5.0.12_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_XC9pYgCc3w from "/webapps/journalistic-pwa/frontend/node_modules/.pnpm/nuxt@3.10.0_eslint@8.56.0_rollup@3.29.4_sass@1.70.0_typescript@5.3.3_vite@5.0.12_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/webapps/journalistic-pwa/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_wYs21cPREg from "/webapps/journalistic-pwa/frontend/node_modules/.pnpm/nuxt@3.10.0_eslint@8.56.0_rollup@3.29.4_sass@1.70.0_typescript@5.3.3_vite@5.0.12_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_kmozNILKwq from "/webapps/journalistic-pwa/frontend/node_modules/.pnpm/v-wave@2.0.0_rollup@3.29.4_vue@3.4.15/node_modules/v-wave/nuxt/runtime/plugin.js";
import plugin_client_BJNBS7Vv4I from "/webapps/journalistic-pwa/frontend/node_modules/.pnpm/@nuxtjs+plausible@0.2.4_rollup@3.29.4/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.mjs";
import plugin_client_aMSXXTX8Pv from "/webapps/journalistic-pwa/frontend/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2_rollup@3.29.4/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import http_plugin_nuxt_cvhatBMg0c from "/webapps/journalistic-pwa/frontend/node_modules/.pnpm/@nuxt-alt+http@1.7.10_nuxi@3.10.0_rollup@3.29.4_sass@1.70.0_typescript@5.3.3/node_modules/@nuxt-alt/http/dist/runtime/http-plugin.nuxt.mjs";
import auth_plugin_6DDHMgc34l from "/webapps/journalistic-pwa/frontend/.nuxt/auth.plugin.mjs";
import chunk_reload_client_cibNl5QvJN from "/webapps/journalistic-pwa/frontend/node_modules/.pnpm/nuxt@3.10.0_eslint@8.56.0_rollup@3.29.4_sass@1.70.0_typescript@5.3.3_vite@5.0.12_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import directives_8CcCirWtnE from "/webapps/journalistic-pwa/frontend/plugins/directives.ts";
import router_WMnTGednOQ from "/webapps/journalistic-pwa/frontend/plugins/router.ts";
import sentry_3AyO8nEfhE from "/webapps/journalistic-pwa/frontend/plugins/sentry.ts";
import shortkeys_kquz2DmlQf from "/webapps/journalistic-pwa/frontend/plugins/shortkeys.ts";
import vuex_F8JCgj45hr from "/webapps/journalistic-pwa/frontend/plugins/vuex.ts";
export default [
  revive_payload_client_WLCqDx0mJK,
  unhead_tfve8oM9m4,
  router_t42dM1Z050,
  payload_client_cQGCIsICzZ,
  check_outdated_build_client_XC9pYgCc3w,
  components_plugin_KR1HBZs4kY,
  prefetch_client_wYs21cPREg,
  plugin_kmozNILKwq,
  plugin_client_BJNBS7Vv4I,
  plugin_client_aMSXXTX8Pv,
  http_plugin_nuxt_cvhatBMg0c,
  auth_plugin_6DDHMgc34l,
  chunk_reload_client_cibNl5QvJN,
  directives_8CcCirWtnE,
  router_WMnTGednOQ,
  sentry_3AyO8nEfhE,
  shortkeys_kquz2DmlQf,
  vuex_F8JCgj45hr
]