import type { GemState } from './state'
import { defaultState } from './state'

export default {
  resetState(state: GemState): void {
    Object.assign(state, defaultState)
  },
  populateGemDict(state: GemState): void {
    state.gemsDict = listMapper(state.gems)
  },
  loadGems(state: GemState, data: Gem[]): void {
    state.gems = data
    state.gemsDict = listMapper(state.gems)
  },
  createGem(state: GemState, data: Gem): void {
    if (!state.gemsDict[data.id]) {
      // Find the correct index where to insert the new Gem based on its creation date.
      const gemCreatedDts = data.created_dts
      let targetIdx = 0
      for (const gem of state.gems) {
        if (gem.created_dts < gemCreatedDts)
          break
        targetIdx++
      }
      state.gemsDict[data.id] = data
      state.gems.splice(targetIdx, 0, data)
    }
  },
  updateGem(state: GemState, gemData: Pick<Gem, 'id'> & Partial<Gem>): void {
    const objId = gemData.id
    const idx = state.gems.findIndex(gem => gem.id === objId)
    const origObj = { ...state.gems[idx] } // Shallow copy.
    const newObj = Object.assign(origObj, gemData) // Allows partial update of object.
    state.gems[idx] = newObj // $set required to keep list reactive.
    state.gemsDict[objId] = newObj
  },
  deleteGem(state: GemState, gemId: GemId): void {
    const idx = state.gems.findIndex(obj => obj.id === gemId)
    state.gems.splice(idx, 1)
    delete state.gemsDict[gemId]
  },
}
