// Inspired by: https://github.com/toniengelhardt/vuex-persistedstate

import merge from 'deepmerge'
import localForage from 'localforage'
import type { Store } from 'vuex'

interface Options<State> {
  baseKey?: string
  modules?: string[]
  paths?: string[]
  skipMutations?: string[]
  serializer?: (value: any) => string
  deserializer?: (value: any) => any
  onRehydrated?: (store: Store<State>) => void
}

const defaultSerializer = JSON.stringify

function defaultDeserializer(value: any) {
  if (typeof value === 'string') {
    return JSON.parse(value)
  }
  else if (typeof value === 'object') {
    return value
  }
  return undefined
}

export function createPersistedState<State>(options: Options<State>): (store: Store<State>) => void {
  const storage = localForage
  const baseKey = options.baseKey || 'vuex'
  const modules = options.modules || []
  const paths = options.paths || []
  const excludedMutations = options.skipMutations || []
  const serializer = options.serializer || defaultSerializer
  const deserializer = options.deserializer || defaultDeserializer
  const onRehydrated = options.onRehydrated || (() => {})

  async function setItem(key: string, item: Partial<State>) {
    return await storage.setItem(key, serializer(item))
  }

  async function getItem(key: string) {
    const value = await storage.getItem(key)
    try {
      return deserializer(value)
    }
    catch (error) {}
    return undefined
  }

  async function rehydrateStore(store: Store<State>) {
    // Rehydrate store when it is initialized.
    console.time('[vuex] reyhdrate')
    const savedState = await getItem(baseKey) || {}
    for (const module of modules) {
      const item = await getItem(`${baseKey}-${module}`)
      if (item) {
        savedState[module] = item
      }
    }
    const mergedState: State = merge(store.state, savedState, {
      arrayMerge: (store, saved) => saved,
      clone: false,
    })
    store.replaceState(mergedState)
    console.timeEnd('[vuex] reyhdrate')
  }

  // Assert storage.
  // Not sure if and why this is needed...
  storage.setItem('@@', '1')
  storage.removeItem('@@')

  return (store: Store<State>) => {
    store.commit('updateState', { ready: false })
    rehydrateStore(store)
      .then(() => {
        // Execute hook after rehydration.
        onRehydrated(store)
        // Subscribe to mutations.
        store.subscribe((mutation, state) => {
          if (!excludedMutations.includes(mutation.type)) {
            // Determine module.
            const partials = mutation.type.split('/')
            const module = partials.length > 1 ? partials[0] : undefined
            if (module) {
              // Get state[module] and save every key that is in 'paths' to vuex-{module}.
              console.time(`[vuex] persist module ${module}`)
              const moduleState: any = state[module as keyof typeof state]
              const persistedModuleState: any = {}
              Object.keys(moduleState).forEach((_key) => {
                if (paths.includes(`${module}.${_key}`)) {
                  persistedModuleState[_key] = moduleState[_key]
                }
              })
              setItem(`${baseKey}-${module}`, persistedModuleState)
              console.timeEnd(`[vuex] persist module ${module}`)
            }
            else {
              // Get everything that is not a module from the state and save it to vuex if the key is in 'paths'.
              console.time('[vuex] persist state')
              const persistedState: any = {}
              Object.keys(state as object).forEach((_key) => {
                if (!modules.includes(_key) && paths.includes(_key)) {
                  persistedState[_key] = state[_key as keyof State]
                }
              })
              setItem(baseKey, persistedState)
              console.timeEnd('[vuex] persist state')
            }
          }
        })
        store.commit('updateState', { ready: true })
      })
  }
}
