import type { DreamState } from './dreams/state'
import type { FocusState } from './focus/state'
import type { GemState } from './gems/state'
import type { IdeaState } from './ideas/state'
import type { JournalState } from './journal/state'
import type { NoteState } from './notes/state'
import type { PeopleState } from './people/state'
import type { TagState } from './tags/state'
import type { TodoState } from './todos/state'
import { PushStates } from '~/utils/sync'
import type { PushState } from '~/utils/sync'
import type { Model, Module } from '~/utils/common'

export interface ProfileSettings {
  day_ends_at: number | undefined
  display_special_dates: boolean
  dreams_enabled: boolean
  goals_enabled: boolean
  focus_mode: FocusMode | null
  notes_enabled: boolean
  reflect_enabled: boolean
  planning_enabled: boolean
  planning_debug_mode: boolean
  gems_enabled: boolean
  ideas_enabled: boolean
  do_more_do_less_enabled: boolean
  do_reflection_enabled: boolean
  pins_enabled: boolean
  day_in_history_enabled: boolean
  auto_tagging_enabled: boolean
  auto_mentioning_enabled: boolean
  entry_items: boolean
  entry_item_indicators: boolean
  davinci_enabled: boolean
  oura_enabled: boolean
  oura_api_key?: string
  freud_enabled: boolean
  // Developer
  action_sync_enabled: boolean
}

export const defaultProfileSettings: ProfileSettings = {
  day_ends_at: 2,
  display_special_dates: true,
  dreams_enabled: true,
  goals_enabled: true,
  focus_mode: null,
  notes_enabled: true,
  reflect_enabled: true,
  planning_enabled: true,
  planning_debug_mode: false,
  gems_enabled: true,
  ideas_enabled: true,
  do_more_do_less_enabled: true,
  do_reflection_enabled: true,
  pins_enabled: true,
  day_in_history_enabled: false,
  auto_tagging_enabled: false,
  auto_mentioning_enabled: false,
  entry_items: true,
  entry_item_indicators: true,
  davinci_enabled: false,
  oura_enabled: false,
  oura_api_key: undefined,
  freud_enabled: false,
  // Developer
  action_sync_enabled: false,
}

/** Device settings (persistent) */

export interface DeviceSettings {
  // theme: Theme
  // dark_mode: boolean
  // dark_mode_auto: boolean
  font_size: number
  spell_checking: boolean
  auto_hide_navigation: boolean
  show_journal_in_navigation: boolean
  dreams_inline_mode: boolean
  collapse_dreams_in_lists: boolean
  collapse_bullets_in_lists: boolean
  highlight_tags_and_mentions: boolean
  autocomplete_enabled: boolean
  notes_inline_mode: boolean
  random_gem_enabled: boolean
  random_idea_enabled: boolean
  streak_enabled: boolean
  focus_reflection_enabled: boolean
  focus_planning_enabled: boolean
  this_week_reflection_enabled: boolean
  one_year_ago_enabled: boolean
  random_entry_enabled: boolean
  show_menu_counters: boolean
  show_entry_counters: boolean // currently not in use.
  show_bullet_timestamps: boolean
  newline_on_enter_in_journal: boolean
  // Developer
  mark_unsynced_objects: boolean
  manual_push: boolean
}

export const defaultDeviceSettings: DeviceSettings = {
  // theme: 'light',
  // dark_mode: false, // Manual dark mode selection.
  // dark_mode_auto: true, // User setting to infer dark mode from device.
  font_size: 16, // Set root font-size as base for rem unit.
  spell_checking: false, // Use the device's spell checking if enabled.
  auto_hide_navigation: false, // Show navigation only on hover.
  show_journal_in_navigation: true,
  dreams_inline_mode: false, // Always show dream input inline on entries.
  collapse_dreams_in_lists: false, // Collapse long dreams in list view.
  collapse_bullets_in_lists: false, // Collapse long bullets in list view.
  highlight_tags_and_mentions: true, // Mark tags and people with an asterisk and link them.
  autocomplete_enabled: true, // Show autocomplete suggestions for tags and people.
  notes_inline_mode: true, // Display notes inline on entries.
  random_gem_enabled: true, // Show/hide random Gem in Reflect.
  random_idea_enabled: true, // Show/hide random Idea in Reflect.
  streak_enabled: true, // Show/hide Streak
  focus_reflection_enabled: true, // Show/hide Focus in Reflect
  focus_planning_enabled: true, // Show/hide Focus in Plan
  this_week_reflection_enabled: true, // Show/hide This Week in Reflect
  one_year_ago_enabled: true, // Show/hide One Year Ago in Reflect
  random_entry_enabled: true, // Show/hide Memory Lane in Reflect
  show_menu_counters: false, // Show/hide item counters for modules in Menu.
  show_entry_counters: true, // Show/hide entry counters in Journal (currently not in use).
  show_bullet_timestamps: false, // Show/hide timestamps beneath bullets.
  newline_on_enter_in_journal: false, // If true use Enter for newlines instead of shift+enter and the latter for saving.
  // Developer settings:
  mark_unsynced_objects: false, // Underline objects that were not synced yet.
  manual_push: false, // If true, don't push actions automatically.
}

export function getDefaultDeviceSettings(): DeviceSettings {
  return Object.assign({}, defaultDeviceSettings)
}

/** Device state (NOT persistent) */

export interface Device {
  twa: boolean | undefined
  storageAPI: boolean | undefined
  vibrationAPI: boolean | undefined
  virtualKeyboardAPI: boolean | undefined
}

export const defaultDeviceState: Device = {
  twa: undefined, // true if the app is in TWA mode.
  storageAPI: undefined,
  vibrationAPI: undefined,
  virtualKeyboardAPI: undefined,
}

/** UI state (persistent) */

export interface UI {
  sideMenuOpen: boolean
  tagsSortingMode: SortingMode
  tagsFilterMode: FilterMode
  tagsTimeframe: InsightsTimeframe
  peopleSortingMode: SortingMode
  peopleFilterMode: FilterMode
  peopleTimeframe: InsightsTimeframe
  insightsTimeframe: InsightsTimeframe
  dailyRandomEntry: {
    date: DateString | undefined
    entryId: EntryId | undefined
  }
  dailyRandomGem: {
    date: DateString | undefined
    gemId: GemId | undefined
  }
  dailyRandomIdea: {
    date: DateString | undefined
    ideaId: IdeaId | undefined
  }
  bulletEditorText: string
}

export const defaultUIState: UI = {
  // Elements:
  sideMenuOpen: false,
  // Tags:
  tagsSortingMode: 'alphabetical',
  tagsFilterMode: undefined,
  tagsTimeframe: undefined, // The default is 365 days.
  // People:
  peopleSortingMode: 'alphabetical',
  peopleFilterMode: undefined,
  peopleTimeframe: undefined, // The default is 365 days.
  // Insights:
  insightsTimeframe: undefined, // The default is 365 days.
  // Random objects:
  dailyRandomEntry: {
    date: undefined,
    entryId: undefined,
  },
  dailyRandomGem: {
    date: undefined,
    gemId: undefined,
  },
  dailyRandomIdea: {
    date: undefined,
    ideaId: undefined,
  },
  // Editors:
  bulletEditorText: '',
}

/** UI state (NOT persistent) */

export interface UIx {
  mobile: undefined | boolean
  locale: undefined | string
  darkModePreferred: boolean
  syncing: boolean
  indicator: {
    label: string
    type: undefined | 'info' | 'success' | 'warning' | 'error'
  } | undefined
  uploading: boolean
  dreamEditModeId: undefined | number
  bulletEditModeId: undefined | number
  todoEditModeId: undefined | number
  todoDragging: boolean
  inputActive: boolean
  // fullscreenMode: boolean
  dialogOpen: boolean
  shortcutsInfoDialoOpen: boolean
}

const defaultUIxState: UIx = {
  mobile: undefined, // Whether or not the device is a mobile device.
  locale: undefined, // Populated on mount from device.
  darkModePreferred: false, // Set to 'true' if the device prefers dark mode (from media query).
  syncing: false, // Set to 'true' if the device is syncing and to 'false' when finished.
  indicator: undefined, // If set, an indicator will be displayed in the app.
  uploading: false, // Displays an upload indicator if set to true.
  dreamEditModeId: undefined, // ID of dream that is currently in edit mode.
  bulletEditModeId: undefined, // ID of bullet that is currently in edit mode.
  todoEditModeId: undefined, // ID of todo that is currently in edit mode.
  todoDragging: false, // Used to sync dragging status between todo lists.
  inputActive: false, // Used to hide menu.
  // fullscreenMode: false, // Used to hide menu.
  dialogOpen: false, // Used to disable scroll.
  shortcutsInfoDialoOpen: false, // Show shortcuts info dialog.
}

export interface Profile {
  id: number
  uid: ProfileUid
  created_sts: DateTimeString
  username: string
  email: string
  account_number: number
  account_type: number
  account_type_name: string
  pro: boolean
  internal: boolean
  beta: boolean
  used_storage_capacity: number
  birthday: string
  last_update_sts: DateTimeString

  // Social links:
  facebook_connected: boolean
  google_connected: boolean

  // Stripe:
  stripe_customer_id: string
  stripe_active_subscriptions: any
}

export interface SyncStatus {
  id: SyncId // This ID is generated by the server, don't change! Whenever the server performs a sync is generates a new sync_id.
  sts: DateTimeString // This timestamp is generated by the server, don't change! Whenever the server performs a sync it generates a new sync_sts.
}

export interface ServerChange {
  module: Module
  model: Model
  action_type: ServerChangeActionType
  sts: DateTimeString
  data: any
}

export interface Sync extends SyncStatus {
  actionQueue: Action[]
  actionsDict: Record<ActionId, Action>
  pushState: PushState
  conflicts: SyncConflict[]
}

export const defaultSyncState = {
  id: undefined,
  sts: undefined,
  actionQueue: [],
  actionsDict: {},
  pushState: PushStates.IDLE,
  conflicts: [],
}

export interface Version {
  pwa: string
  db: string
}

export const defaultVersionState = {
  pwa: '', // PWA version, updates on every deployment.
  db: '', // DB version, updates whenever data structure changes (forces full refresh).
}

export interface RootState {
  // Properties
  profile: Profile | undefined
  profileSettings: ProfileSettings | undefined
  device: Device
  deviceSettings: DeviceSettings | undefined
  sync: Sync
  ui: UI
  uix: UIx
  version: Version
  // Modules
  dreams: DreamState
  focus: FocusState
  gems: GemState
  ideas: IdeaState
  journal: JournalState
  notes: NoteState
  people: PeopleState
  tags: TagState
  todos: TodoState
}

export type RootStateKey = keyof RootState

export function getDefaultState(): RootState {
  return Object.assign(
    { ready: false },
    { profile: undefined },
    { profileSettings: undefined }, // Always populated from profile if set, otherwise from default
    { device: defaultDeviceState },
    { deviceSettings: undefined }, // Initially populated from profile if set, otherwise from default
    { sync: defaultSyncState },
    { ui: defaultUIState },
    { uix: defaultUIxState },
    { version: defaultVersionState },
  )
}

// State refactoring:
// - Combine profile settings and device settings into one state and rename to
//   settings: { global: ..., local: ... }

export default getDefaultState
