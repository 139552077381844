import { differenceInDays, differenceInMonths, format } from 'date-fns'
import type { Device, DeviceSettings, Profile, ProfileSettings, RootState, Sync, UI, UIx } from './state'
import { ACCOUNT_TYPES } from '~/utils/account'

export default {
  device(state: RootState): Device {
    return state.device
  },
  sync(state: RootState): Sync {
    return state.sync
  },
  profile(state: RootState): Profile | undefined {
    return state.profile
  },
  journalStartDt(state: RootState, getters: any): Date | undefined {
    if (state.profile) {
      const earliestDate = getters['journal/firstEntryDate'] || state.profile.created_sts
      return new Date(earliestDate)
    }
    return undefined
  },
  journalStartDate(_: RootState, getters: any): DateString | undefined {
    return getters.journalStartDt ? format(new Date(getters.journalStartDt), 'yyyy-MM-dd') : undefined
  },
  journalAgeInDays(_: RootState, getters: any): number {
    return differenceInDays(new Date(), getters.journalStartDt)
  },
  journalAgeInMonths(_: RootState, getters: any): number {
    return differenceInMonths(new Date(), getters.journalStartDt)
  },
  pro(state: RootState) {
    return state.profile && (
      state.profile.pro
      || ['invite-only', 'hall-of-fame', 'pro'].includes(ACCOUNT_TYPES[state.profile.account_type]?.name)
    )
  },
  profileSettings(state: RootState): ProfileSettings | object {
    return state.profileSettings || {}
  },
  deviceSettings(state: RootState): DeviceSettings | object {
    return state.deviceSettings || {}
  },
  ui(state: RootState): UI {
    return state.ui
  },
  uix(state: RootState): UIx {
    return state.uix
  },
  ouraWidgetAvailable(_state: RootState, getters: any): boolean {
    return getters.profileSettings?.beta
      && getters.profileSettings?.davinci_enabled
      && getters.profileSettings?.oura_enabled
  },
}
