import validate from "/webapps/journalistic-pwa/frontend/node_modules/.pnpm/nuxt@3.10.0_eslint@8.56.0_rollup@3.29.4_sass@1.70.0_typescript@5.3.3_vite@5.0.12_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth from "/webapps/journalistic-pwa/frontend/node_modules/.pnpm/@nuxt-alt+auth@3.1.7-beta.1_nuxi@3.10.0_rollup@3.29.4_sass@1.70.0_typescript@5.3.3_vue@3.4.15/node_modules/@nuxt-alt/auth/dist/runtime/core/middleware.mjs";
import manifest_45route_45rule from "/webapps/journalistic-pwa/frontend/node_modules/.pnpm/nuxt@3.10.0_eslint@8.56.0_rollup@3.29.4_sass@1.70.0_typescript@5.3.3_vite@5.0.12_vue-tsc@1.8.27/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth,
  manifest_45route_45rule
]
export const namedMiddleware = {
  pro: () => import("/webapps/journalistic-pwa/frontend/middleware/pro.ts")
}