import { Auth, ExpiredAuthSessionError } from '#auth/runtime'
import { defineNuxtPlugin, useRuntimeConfig } from '#imports'
import { defu } from 'defu';

// Active schemes
import { RefreshScheme } from '#auth/runtime'
import { Oauth2Scheme } from '#auth/runtime'

// Options
let options = {
    "globalMiddleware": true,
    "enableMiddleware": true,
    "resetOnError": false,
    "resetOnResponseError": false,
    "ignoreExceptions": false,
    "scopeKey": "scope",
    "rewriteRedirects": true,
    "fullPathRedirect": false,
    "redirectStrategy": "query",
    "watchLoggedIn": true,
    "tokenValidationInterval": false,
    "redirect": {
        "login": "\u002Flogin",
        "logout": "\u002Flogin",
        "home": "\u002Fwrite",
        "callback": "\u002Flogin"
    },
    "stores": {
        "state": {
            "namespace": "auth"
        },
        "pinia": {
            "enabled": false,
            "namespace": "auth"
        },
        "cookie": {
            "enabled": true,
            "prefix": "auth.",
            "options": {
                "path": "\u002F",
                "sameSite": "lax",
                "maxAge": 31536000,
                "secure": true
            },
            "secure": true
        },
        "local": {
            "enabled": false,
            "prefix": "auth."
        },
        "session": {
            "enabled": false,
            "prefix": "auth."
        }
    },
    "defaultStrategy": "local"
}

export default defineNuxtPlugin({
    name: 'nuxt-alt:auth',
    async setup(nuxtApp) {
        // Create a new Auth instance
        const auth = new Auth(nuxtApp, options)

        // Register strategies
        auth.registerStrategy('local', new RefreshScheme(auth, defu(useRuntimeConfig()?.public?.auth?.strategies?.['local'], {"token":{"property":"access","type":"JWT"},"refreshToken":{"property":"refresh","data":"refresh"},"user":{"property":false},"endpoints":{"login":{"url":"https://pwa.journalisticapp.com/api/auth/token/","method":"post"},"refresh":{"url":"https://pwa.journalisticapp.com/api/auth/token/refresh/","method":"post"},"user":false,"logout":{"url":"https://pwa.journalisticapp.com/api/user/logout/","method":"post"}},"name":"local","ssr":false})));
auth.registerStrategy('facebook', new Oauth2Scheme(auth, defu(useRuntimeConfig()?.public?.auth?.strategies?.['facebook'], {"clientId":"542520533145280","responseType":"code","endpoints":{"authorization":"https://facebook.com/v2.12/dialog/oauth","userInfo":"https://pwa.journalisticapp.com/api/user/","token":"https://pwa.journalisticapp.com/auth/social/facebook/"},"token":{"property":"access_token","expiresProperty":"expires_in","type":"JWT","name":"Authorization","maxAge":false,"global":true,"prefix":"_token.","expirationPrefix":"_token_expiration."},"name":"facebook","ssr":false,"clientSecretTransport":"body","autoLogout":false,"scope":["public_profile","email"],"idToken":{"property":"id_token","maxAge":1800,"prefix":"_id_token.","expirationPrefix":"_id_token_expiration.","httpOnly":false},"refreshToken":{"property":"refresh_token","maxAge":2592000,"prefix":"_refresh_token.","expirationPrefix":"_refresh_token_expiration.","httpOnly":false},"user":{"property":false},"codeChallengeMethod":false,"clientWindow":false,"clientWindowWidth":400,"clientWindowHeight":600})));
auth.registerStrategy('google', new Oauth2Scheme(auth, defu(useRuntimeConfig()?.public?.auth?.strategies?.['google'], {"clientId":"1039237379795-llbu67kmv0dgaoga6in2ljdm1fetrdud.apps.googleusercontent.com","scope":["profile","email","openid","profile","email"],"responseType":"code","codeChallengeMethod":"","endpoints":{"authorization":"https://accounts.google.com/o/oauth2/v2/auth","userInfo":"https://pwa.journalisticapp.com/api/user/","token":"https://pwa.journalisticapp.com/auth/social/google/"},"token":{"property":"access","expiresProperty":"expires_in","type":"JWT","name":"Authorization","maxAge":false,"global":true,"prefix":"_token.","expirationPrefix":"_token_expiration."},"refreshToken":{"property":"refresh","maxAge":2592000,"prefix":"_refresh_token.","expirationPrefix":"_refresh_token_expiration.","httpOnly":false},"name":"google","ssr":false,"clientSecretTransport":"body","autoLogout":false,"idToken":{"property":"id_token","maxAge":1800,"prefix":"_id_token.","expirationPrefix":"_id_token_expiration.","httpOnly":false},"user":{"property":false},"clientWindow":false,"clientWindowWidth":400,"clientWindowHeight":600})))

        nuxtApp.provide('auth', auth)

        return auth.init()
        .catch(error => {
            if (process.client) {
                // Don't console log expired auth session errors. This error is common, and expected to happen.
                // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
                // token. We don't want to log this as an error.
                if (error instanceof ExpiredAuthSessionError) {
                    return
                }

                console.error('[ERROR] [AUTH]', error)
            }
        })
    }
})