import { addHours, format } from 'date-fns'
import type { RootState } from '../state'
import type { TodoState } from './state'

export default {
  todos(state: TodoState): Todo[] {
    return state.todos
  },
  todosDict(state: TodoState): Record<TodoId, Todo> {
    return state.todosDict
  },
  todosForToday(state: TodoState, _: any, rootState: RootState): Todo[] {
    const todayDateString = format(new Date(), 'yyyy-MM-dd')
    const activeDateString = format(getActiveDate(rootState.profileSettings?.day_ends_at), 'yyyy-MM-dd')
    return state.todos.filter((todo: Todo) => (
      todo && todo.due_date <= todayDateString && (!todo.completed_dts || [todayDateString, activeDateString].includes(todo.completed_dts?.substring(0, 10)))
    ))
  },
  todosForTomorrow(state: TodoState, _: any, rootState: RootState): Todo[] {
    const todayDateString = format(new Date(), 'yyyy-MM-dd')
    const activeDate = getActiveDate(rootState.profileSettings?.day_ends_at)
    const activeDateString = format(activeDate, 'yyyy-MM-dd')
    const tomorrowDateString = format(addHours(activeDate, 24), 'yyyy-MM-dd')
    return state.todos.filter((todo: Todo) => (
      todo && todo.due_date === tomorrowDateString && (!todo.completed_dts || [todayDateString, activeDateString].includes(todo.completed_dts?.substring(0, 10)))
    ))
  },
  todosForLater(state: TodoState, _: any, rootState: RootState): Todo[] {
    const todayDateString = format(new Date(), 'yyyy-MM-dd')
    const activeDateString = format(getActiveDate(rootState.profileSettings?.day_ends_at), 'yyyy-MM-dd')
    return state.todos.filter((todo: Todo) => (
      todo && !todo.due_date && (!todo.completed_dts || [todayDateString, activeDateString].includes(todo.completed_dts?.substring(0, 10)))
    ))
  },
  completedTodos(state: TodoState): Todo[] {
    return state.todos.filter((todo: Todo) => !!todo.completed_dts)
  },
}
