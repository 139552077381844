import type { ActionContext } from 'vuex'
import type { IdeaState } from './state'
import type { RootState } from '~/store/state'

export default {
  resetState({ commit }: ActionContext<IdeaState, RootState>): void {
    commit('resetState')
  },
  fetchData({ commit }: ActionContext<IdeaState, RootState>): Promise<void> {
    return $http
      .$get('/ideas/')
      .then((data: Idea[]) => commit('loadIdeas', data))
      .catch((error: any) => console.error('fetchIdeas', error))
  },
  createIdea(
    { commit, dispatch, rootState }: ActionContext<IdeaState, RootState>,
    data: Omit<Idea, 'created_dts' | 'updated_dts'>,
  ): Promise<{ ideaId: IdeaId, actionId: ActionId } | void> {
    data.id = data.id || uuid4()
    const timestamp = new Date().toISOString()
    const idea = {
      ...data,
      created_dts: timestamp,
      updated_dts: timestamp,
      synced: false,
    }
    // If an entry is submitted, populate the date from the entry,
    // otherwise generate the date and check if a matching entry exists.
    if (idea.entry) {
      idea.date = rootState.journal.entriesDict[idea.entry].date
    }
    else {
      const activeDate = getActiveDateString(rootState.profileSettings?.day_ends_at)
      idea.date = activeDate
      idea.entry = rootState.journal.entries.find(entry => entry.date === activeDate)?.id || undefined
    }
    commit('createIdea', idea)
    if (idea.entry) {
      commit('journal/addIdeaToEntry', { entryId: idea.entry, ideaId: idea.id }, { root: true })
    }
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.IDEAS,
        model: Models.IDEA,
        action_type: ActionTypes.CREATE,
        data: idea,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ ideaId: data.id, actionId: action.id })
    }
    else {
      return $http
        .$post('ideas/', { body: idea })
        .catch((error: any) => console.error('createIdea', error))
    }
  },
  updateIdea(
    { commit, dispatch, rootState }: ActionContext<IdeaState, RootState>,
    data: Pick<Idea, 'id'> & Partial<Omit<Idea, 'created_dts' | 'updated_dts'>>,
  ): Promise<{ actionId: ActionId } | void> {
    const idea = {
      ...data,
      updated_dts: new Date().toISOString(),
      synced: false,
    }
    commit('updateIdea', idea)
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.IDEAS,
        model: Models.IDEA,
        action_type: ActionTypes.UPDATE,
        data: idea,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$patch(`ideas/${idea.id}/`, { body: idea })
        .catch((error: any) => console.error('updateIdea', error))
    }
  },
  addEntryToIdea({ commit, dispatch, rootState }: ActionContext<IdeaState, RootState>, data: { ideaId: IdeaId, entryId: EntryId }): Promise<{ actionId: ActionId } | void> {
    const ideaChanges = {
      id: data.ideaId,
      entry: data.entryId,
    }
    commit('updateIdea', ideaChanges)
    commit('journal/addIdeaToEntry', { entryId: data.entryId, ideaId: data.ideaId }, { root: true })
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.IDEAS,
        model: Models.IDEA,
        action_type: ActionTypes.UPDATE,
        data: ideaChanges,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$patch(`ideas/${data.ideaId}/`, { body: { entry: data.entryId } })
        .catch((error: any) => console.error('addEntryToIdea', error))
    }
  },
  removeEntryFromIdea({ commit, dispatch, rootState }: ActionContext<IdeaState, RootState>, data: { ideaId: IdeaId, entryId: EntryId }): Promise<{ actionId: ActionId } | void> {
    const ideaChanges = {
      id: data.ideaId,
      entry: null,
    }
    commit('updateIdea', ideaChanges)
    commit('journal/removeIdeaFromEntry', { entryId: data.entryId, ideaId: data.ideaId }, { root: true })
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.IDEAS,
        model: Models.IDEA,
        action_type: ActionTypes.UPDATE,
        data: ideaChanges,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$patch(`ideas/${data.ideaId}/`, { body: { entry: null } })
        .catch((error: any) => console.error('addEntryToIdea', error))
    }
  },
  deleteIdea({ commit, dispatch, rootState, state }: ActionContext<IdeaState, RootState>, ideaId: IdeaId): Promise<{ actionId: ActionId } | void> {
    const idea = state.ideasDict[ideaId]
    if (idea.entry) {
      commit('journal/removeIdeaFromEntry', { entryId: idea.entry, ideaId }, { root: true })
    }
    commit('deleteIdea', ideaId)
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.IDEAS,
        model: Models.IDEA,
        action_type: ActionTypes.DELETE,
        data: {
          id: ideaId,
          deleted_dts: new Date().toISOString(),
        },
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$delete(`ideas/${ideaId}/`)
        .catch((error: Error) => console.error('deleteIdea', error))
    }
  },
}
