import { default as indexDNM5BiszUhMeta } from "/webapps/journalistic-pwa/frontend/pages/account/index.vue?macro=true";
import { default as proHpodZ3jO37Meta } from "/webapps/journalistic-pwa/frontend/pages/account/pro.vue?macro=true";
import { default as update2GKwXR5XLWMeta } from "/webapps/journalistic-pwa/frontend/pages/account/update.vue?macro=true";
import { default as action_45queue5yxgZMQDoxMeta } from "/webapps/journalistic-pwa/frontend/pages/action-queue.vue?macro=true";
import { default as _91uuid_932TLzkW5ijmMeta } from "/webapps/journalistic-pwa/frontend/pages/activate/[uuid].vue?macro=true";
import { default as confirm1LYBOoWjsDMeta } from "/webapps/journalistic-pwa/frontend/pages/confirm.vue?macro=true";
import { default as developerWZ8QCEjcG0Meta } from "/webapps/journalistic-pwa/frontend/pages/developer.vue?macro=true";
import { default as indexmhOPJ546RcMeta } from "/webapps/journalistic-pwa/frontend/pages/dreams/index.vue?macro=true";
import { default as indexg0phbns5PRMeta } from "/webapps/journalistic-pwa/frontend/pages/focus/[id]/index.vue?macro=true";
import { default as indexXVuvRGDbXGMeta } from "/webapps/journalistic-pwa/frontend/pages/focus/index.vue?macro=true";
import { default as indexXWwKysPgspMeta } from "/webapps/journalistic-pwa/frontend/pages/highlights/index.vue?macro=true";
import { default as indexa3zA56pcZ2Meta } from "/webapps/journalistic-pwa/frontend/pages/ideas/[id]/index.vue?macro=true";
import { default as index5kT52qZXMcMeta } from "/webapps/journalistic-pwa/frontend/pages/ideas/index.vue?macro=true";
import { default as dreamsDb4sZo0PvmMeta } from "/webapps/journalistic-pwa/frontend/pages/insights/index/dreams.vue?macro=true";
import { default as highlightshVs62pDensMeta } from "/webapps/journalistic-pwa/frontend/pages/insights/index/highlights.vue?macro=true";
import { default as ideas8735NBTXKhMeta } from "/webapps/journalistic-pwa/frontend/pages/insights/index/ideas.vue?macro=true";
import { default as index9anfTLIXCxMeta } from "/webapps/journalistic-pwa/frontend/pages/insights/index/index.vue?macro=true";
import { default as peoplePdNszMDcmDMeta } from "/webapps/journalistic-pwa/frontend/pages/insights/index/people.vue?macro=true";
import { default as tagsic1aKwCybsMeta } from "/webapps/journalistic-pwa/frontend/pages/insights/index/tags.vue?macro=true";
import { default as wisdomNKhEfkktrkMeta } from "/webapps/journalistic-pwa/frontend/pages/insights/index/wisdom.vue?macro=true";
import { default as indexsSJkqdWl2iMeta } from "/webapps/journalistic-pwa/frontend/pages/insights/index.vue?macro=true";
import { default as indexiGR8qt8dlnMeta } from "/webapps/journalistic-pwa/frontend/pages/journal/[id]/index.vue?macro=true";
import { default as indexRCRut3RjQLMeta } from "/webapps/journalistic-pwa/frontend/pages/journal/index.vue?macro=true";
import { default as emailxivespCnpVMeta } from "/webapps/journalistic-pwa/frontend/pages/login/email.vue?macro=true";
import { default as indexrLv2UES2ljMeta } from "/webapps/journalistic-pwa/frontend/pages/login/index.vue?macro=true";
import { default as loginbjVD2VBrSkMeta } from "/webapps/journalistic-pwa/frontend/pages/login.vue?macro=true";
import { default as logoutcwg2WsJv99Meta } from "/webapps/journalistic-pwa/frontend/pages/logout.vue?macro=true";
import { default as indexQpHCamhOtvMeta } from "/webapps/journalistic-pwa/frontend/pages/look-ahead/completed-items/index.vue?macro=true";
import { default as index7dRGmP1dtUMeta } from "/webapps/journalistic-pwa/frontend/pages/look-ahead/index.vue?macro=true";
import { default as _91uuid_93CfybP6kpFnMeta } from "/webapps/journalistic-pwa/frontend/pages/ml/[uuid].vue?macro=true";
import { default as indexE6AzBtaj0XMeta } from "/webapps/journalistic-pwa/frontend/pages/notes/[id]/index.vue?macro=true";
import { default as indexU71Ep9v4NNMeta } from "/webapps/journalistic-pwa/frontend/pages/notes/index/index.vue?macro=true";
import { default as labelsJ0D3KYifpNMeta } from "/webapps/journalistic-pwa/frontend/pages/notes/index/labels.vue?macro=true";
import { default as indexOdmscGlP5TMeta } from "/webapps/journalistic-pwa/frontend/pages/notes/index.vue?macro=true";
import { default as index0xV2gE3xAGMeta } from "/webapps/journalistic-pwa/frontend/pages/notes/labels/[id]/index.vue?macro=true";
import { default as detailso9iTha7qpHMeta } from "/webapps/journalistic-pwa/frontend/pages/people/[id]/index/details.vue?macro=true";
import { default as indexrmCFejDOqdMeta } from "/webapps/journalistic-pwa/frontend/pages/people/[id]/index/index.vue?macro=true";
import { default as insights273pTrnt9qMeta } from "/webapps/journalistic-pwa/frontend/pages/people/[id]/index/insights.vue?macro=true";
import { default as indexuoKHbnPpzlMeta } from "/webapps/journalistic-pwa/frontend/pages/people/[id]/index.vue?macro=true";
import { default as indexHRXgLnIHq2Meta } from "/webapps/journalistic-pwa/frontend/pages/people/groups/[id]/index/index.vue?macro=true";
import { default as infoGnu9sROnz2Meta } from "/webapps/journalistic-pwa/frontend/pages/people/groups/[id]/index/info.vue?macro=true";
import { default as insightsYsM2lwh88yMeta } from "/webapps/journalistic-pwa/frontend/pages/people/groups/[id]/index/insights.vue?macro=true";
import { default as people5MZiJKm0KyMeta } from "/webapps/journalistic-pwa/frontend/pages/people/groups/[id]/index/people.vue?macro=true";
import { default as indexeBGQ4mGeeBMeta } from "/webapps/journalistic-pwa/frontend/pages/people/groups/[id]/index.vue?macro=true";
import { default as groupsbmrazhjHQRMeta } from "/webapps/journalistic-pwa/frontend/pages/people/index/groups.vue?macro=true";
import { default as indexYD0Mm6uZh8Meta } from "/webapps/journalistic-pwa/frontend/pages/people/index/index.vue?macro=true";
import { default as index5xQmma36ikMeta } from "/webapps/journalistic-pwa/frontend/pages/people/index.vue?macro=true";
import { default as indexJ60w8HKmAeMeta } from "/webapps/journalistic-pwa/frontend/pages/reflect/index.vue?macro=true";
import { default as emailShZ2UF7WDNMeta } from "/webapps/journalistic-pwa/frontend/pages/register/email.vue?macro=true";
import { default as indexcHX0mFVYDUMeta } from "/webapps/journalistic-pwa/frontend/pages/register/index.vue?macro=true";
import { default as registerPqoBbWbUPMMeta } from "/webapps/journalistic-pwa/frontend/pages/register.vue?macro=true";
import { default as reset_45passwordMXIqPcKr6eMeta } from "/webapps/journalistic-pwa/frontend/pages/reset-password.vue?macro=true";
import { default as settingsd8hDdB3JZGMeta } from "/webapps/journalistic-pwa/frontend/pages/settings.vue?macro=true";
import { default as styleboardQwZsZ3uSq7Meta } from "/webapps/journalistic-pwa/frontend/pages/styleboard.vue?macro=true";
import { default as detailsO29i7tnby2Meta } from "/webapps/journalistic-pwa/frontend/pages/tags/[id]/index/details.vue?macro=true";
import { default as indexIU3rHm67uFMeta } from "/webapps/journalistic-pwa/frontend/pages/tags/[id]/index/index.vue?macro=true";
import { default as insights72QjCDsawKMeta } from "/webapps/journalistic-pwa/frontend/pages/tags/[id]/index/insights.vue?macro=true";
import { default as indexQEmLQ4rnVaMeta } from "/webapps/journalistic-pwa/frontend/pages/tags/[id]/index.vue?macro=true";
import { default as index8ddSRRdOewMeta } from "/webapps/journalistic-pwa/frontend/pages/tags/groups/[id]/index/index.vue?macro=true";
import { default as infoLcqey3fPgOMeta } from "/webapps/journalistic-pwa/frontend/pages/tags/groups/[id]/index/info.vue?macro=true";
import { default as insightsOKDI2DHNVdMeta } from "/webapps/journalistic-pwa/frontend/pages/tags/groups/[id]/index/insights.vue?macro=true";
import { default as tagsKrA06bEXLZMeta } from "/webapps/journalistic-pwa/frontend/pages/tags/groups/[id]/index/tags.vue?macro=true";
import { default as indexckvTUHiSr1Meta } from "/webapps/journalistic-pwa/frontend/pages/tags/groups/[id]/index.vue?macro=true";
import { default as groups1rMHIIxXDHMeta } from "/webapps/journalistic-pwa/frontend/pages/tags/index/groups.vue?macro=true";
import { default as indexLxbpZUGw1xMeta } from "/webapps/journalistic-pwa/frontend/pages/tags/index/index.vue?macro=true";
import { default as indexAt7ywSjimPMeta } from "/webapps/journalistic-pwa/frontend/pages/tags/index.vue?macro=true";
import { default as updates6g3D2w7v6IMeta } from "/webapps/journalistic-pwa/frontend/pages/updates.vue?macro=true";
import { default as websocket_45testbzGvR3b04tMeta } from "/webapps/journalistic-pwa/frontend/pages/websocket-test.vue?macro=true";
import { default as indexTcPPPZe8ROMeta } from "/webapps/journalistic-pwa/frontend/pages/wisdom/[id]/index.vue?macro=true";
import { default as indexriWJ95Yh4AMeta } from "/webapps/journalistic-pwa/frontend/pages/wisdom/index.vue?macro=true";
import { default as index7tYRxj1oRwMeta } from "/webapps/journalistic-pwa/frontend/pages/write/index.vue?macro=true";
export default [
  {
    name: indexDNM5BiszUhMeta?.name ?? "account",
    path: indexDNM5BiszUhMeta?.path ?? "/account",
    meta: indexDNM5BiszUhMeta || {},
    alias: indexDNM5BiszUhMeta?.alias || [],
    redirect: indexDNM5BiszUhMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: proHpodZ3jO37Meta?.name ?? "account-pro",
    path: proHpodZ3jO37Meta?.path ?? "/account/pro",
    meta: proHpodZ3jO37Meta || {},
    alias: proHpodZ3jO37Meta?.alias || [],
    redirect: proHpodZ3jO37Meta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/account/pro.vue").then(m => m.default || m)
  },
  {
    name: update2GKwXR5XLWMeta?.name ?? "account-update",
    path: update2GKwXR5XLWMeta?.path ?? "/account/update",
    meta: update2GKwXR5XLWMeta || {},
    alias: update2GKwXR5XLWMeta?.alias || [],
    redirect: update2GKwXR5XLWMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/account/update.vue").then(m => m.default || m)
  },
  {
    name: action_45queue5yxgZMQDoxMeta?.name ?? "action-queue",
    path: action_45queue5yxgZMQDoxMeta?.path ?? "/action-queue",
    meta: action_45queue5yxgZMQDoxMeta || {},
    alias: action_45queue5yxgZMQDoxMeta?.alias || [],
    redirect: action_45queue5yxgZMQDoxMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/action-queue.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_932TLzkW5ijmMeta?.name ?? "activate-uuid",
    path: _91uuid_932TLzkW5ijmMeta?.path ?? "/activate/:uuid()",
    meta: _91uuid_932TLzkW5ijmMeta || {},
    alias: _91uuid_932TLzkW5ijmMeta?.alias || [],
    redirect: _91uuid_932TLzkW5ijmMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/activate/[uuid].vue").then(m => m.default || m)
  },
  {
    name: confirm1LYBOoWjsDMeta?.name ?? "confirm",
    path: confirm1LYBOoWjsDMeta?.path ?? "/confirm",
    meta: confirm1LYBOoWjsDMeta || {},
    alias: confirm1LYBOoWjsDMeta?.alias || [],
    redirect: confirm1LYBOoWjsDMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: developerWZ8QCEjcG0Meta?.name ?? "developer",
    path: developerWZ8QCEjcG0Meta?.path ?? "/developer",
    meta: developerWZ8QCEjcG0Meta || {},
    alias: developerWZ8QCEjcG0Meta?.alias || [],
    redirect: developerWZ8QCEjcG0Meta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/developer.vue").then(m => m.default || m)
  },
  {
    name: indexmhOPJ546RcMeta?.name ?? "dreams",
    path: indexmhOPJ546RcMeta?.path ?? "/dreams",
    meta: indexmhOPJ546RcMeta || {},
    alias: indexmhOPJ546RcMeta?.alias || [],
    redirect: indexmhOPJ546RcMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/dreams/index.vue").then(m => m.default || m)
  },
  {
    name: indexg0phbns5PRMeta?.name ?? "focus-id",
    path: indexg0phbns5PRMeta?.path ?? "/focus/:id()",
    meta: indexg0phbns5PRMeta || {},
    alias: indexg0phbns5PRMeta?.alias || [],
    redirect: indexg0phbns5PRMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/focus/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexXVuvRGDbXGMeta?.name ?? "focus",
    path: indexXVuvRGDbXGMeta?.path ?? "/focus",
    meta: indexXVuvRGDbXGMeta || {},
    alias: indexXVuvRGDbXGMeta?.alias || [],
    redirect: indexXVuvRGDbXGMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/focus/index.vue").then(m => m.default || m)
  },
  {
    name: indexXWwKysPgspMeta?.name ?? "highlights",
    path: indexXWwKysPgspMeta?.path ?? "/highlights",
    meta: indexXWwKysPgspMeta || {},
    alias: indexXWwKysPgspMeta?.alias || [],
    redirect: indexXWwKysPgspMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/highlights/index.vue").then(m => m.default || m)
  },
  {
    name: indexa3zA56pcZ2Meta?.name ?? "ideas-id",
    path: indexa3zA56pcZ2Meta?.path ?? "/ideas/:id()",
    meta: indexa3zA56pcZ2Meta || {},
    alias: indexa3zA56pcZ2Meta?.alias || [],
    redirect: indexa3zA56pcZ2Meta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/ideas/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index5kT52qZXMcMeta?.name ?? "ideas",
    path: index5kT52qZXMcMeta?.path ?? "/ideas",
    meta: index5kT52qZXMcMeta || {},
    alias: index5kT52qZXMcMeta?.alias || [],
    redirect: index5kT52qZXMcMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/ideas/index.vue").then(m => m.default || m)
  },
  {
    name: indexsSJkqdWl2iMeta?.name ?? undefined,
    path: indexsSJkqdWl2iMeta?.path ?? "/insights",
    meta: indexsSJkqdWl2iMeta || {},
    alias: indexsSJkqdWl2iMeta?.alias || [],
    redirect: indexsSJkqdWl2iMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/insights/index.vue").then(m => m.default || m),
    children: [
  {
    name: dreamsDb4sZo0PvmMeta?.name ?? "insights-index-dreams",
    path: dreamsDb4sZo0PvmMeta?.path ?? "dreams",
    meta: dreamsDb4sZo0PvmMeta || {},
    alias: dreamsDb4sZo0PvmMeta?.alias || [],
    redirect: dreamsDb4sZo0PvmMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/insights/index/dreams.vue").then(m => m.default || m)
  },
  {
    name: highlightshVs62pDensMeta?.name ?? "insights-index-highlights",
    path: highlightshVs62pDensMeta?.path ?? "highlights",
    meta: highlightshVs62pDensMeta || {},
    alias: highlightshVs62pDensMeta?.alias || [],
    redirect: highlightshVs62pDensMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/insights/index/highlights.vue").then(m => m.default || m)
  },
  {
    name: ideas8735NBTXKhMeta?.name ?? "insights-index-ideas",
    path: ideas8735NBTXKhMeta?.path ?? "ideas",
    meta: ideas8735NBTXKhMeta || {},
    alias: ideas8735NBTXKhMeta?.alias || [],
    redirect: ideas8735NBTXKhMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/insights/index/ideas.vue").then(m => m.default || m)
  },
  {
    name: index9anfTLIXCxMeta?.name ?? "insights-index",
    path: index9anfTLIXCxMeta?.path ?? "",
    meta: index9anfTLIXCxMeta || {},
    alias: index9anfTLIXCxMeta?.alias || [],
    redirect: index9anfTLIXCxMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/insights/index/index.vue").then(m => m.default || m)
  },
  {
    name: peoplePdNszMDcmDMeta?.name ?? "insights-index-people",
    path: peoplePdNszMDcmDMeta?.path ?? "people",
    meta: peoplePdNszMDcmDMeta || {},
    alias: peoplePdNszMDcmDMeta?.alias || [],
    redirect: peoplePdNszMDcmDMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/insights/index/people.vue").then(m => m.default || m)
  },
  {
    name: tagsic1aKwCybsMeta?.name ?? "insights-index-tags",
    path: tagsic1aKwCybsMeta?.path ?? "tags",
    meta: tagsic1aKwCybsMeta || {},
    alias: tagsic1aKwCybsMeta?.alias || [],
    redirect: tagsic1aKwCybsMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/insights/index/tags.vue").then(m => m.default || m)
  },
  {
    name: wisdomNKhEfkktrkMeta?.name ?? "insights-index-wisdom",
    path: wisdomNKhEfkktrkMeta?.path ?? "wisdom",
    meta: wisdomNKhEfkktrkMeta || {},
    alias: wisdomNKhEfkktrkMeta?.alias || [],
    redirect: wisdomNKhEfkktrkMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/insights/index/wisdom.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexiGR8qt8dlnMeta?.name ?? "journal-id",
    path: indexiGR8qt8dlnMeta?.path ?? "/journal/:id()",
    meta: indexiGR8qt8dlnMeta || {},
    alias: indexiGR8qt8dlnMeta?.alias || [],
    redirect: indexiGR8qt8dlnMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/journal/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRCRut3RjQLMeta?.name ?? "journal",
    path: indexRCRut3RjQLMeta?.path ?? "/journal",
    meta: indexRCRut3RjQLMeta || {},
    alias: indexRCRut3RjQLMeta?.alias || [],
    redirect: indexRCRut3RjQLMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/journal/index.vue").then(m => m.default || m)
  },
  {
    name: loginbjVD2VBrSkMeta?.name ?? undefined,
    path: loginbjVD2VBrSkMeta?.path ?? "/login",
    meta: loginbjVD2VBrSkMeta || {},
    alias: loginbjVD2VBrSkMeta?.alias || [],
    redirect: loginbjVD2VBrSkMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/login.vue").then(m => m.default || m),
    children: [
  {
    name: emailxivespCnpVMeta?.name ?? "login-email",
    path: emailxivespCnpVMeta?.path ?? "email",
    meta: emailxivespCnpVMeta || {},
    alias: emailxivespCnpVMeta?.alias || [],
    redirect: emailxivespCnpVMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/login/email.vue").then(m => m.default || m)
  },
  {
    name: indexrLv2UES2ljMeta?.name ?? "login",
    path: indexrLv2UES2ljMeta?.path ?? "",
    meta: indexrLv2UES2ljMeta || {},
    alias: indexrLv2UES2ljMeta?.alias || [],
    redirect: indexrLv2UES2ljMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/login/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: logoutcwg2WsJv99Meta?.name ?? "logout",
    path: logoutcwg2WsJv99Meta?.path ?? "/logout",
    meta: logoutcwg2WsJv99Meta || {},
    alias: logoutcwg2WsJv99Meta?.alias || [],
    redirect: logoutcwg2WsJv99Meta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: indexQpHCamhOtvMeta?.name ?? "look-ahead-completed-items",
    path: indexQpHCamhOtvMeta?.path ?? "/look-ahead/completed-items",
    meta: indexQpHCamhOtvMeta || {},
    alias: indexQpHCamhOtvMeta?.alias || [],
    redirect: indexQpHCamhOtvMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/look-ahead/completed-items/index.vue").then(m => m.default || m)
  },
  {
    name: index7dRGmP1dtUMeta?.name ?? "look-ahead",
    path: index7dRGmP1dtUMeta?.path ?? "/look-ahead",
    meta: index7dRGmP1dtUMeta || {},
    alias: index7dRGmP1dtUMeta?.alias || [],
    redirect: index7dRGmP1dtUMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/look-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93CfybP6kpFnMeta?.name ?? "ml-uuid",
    path: _91uuid_93CfybP6kpFnMeta?.path ?? "/ml/:uuid()",
    meta: _91uuid_93CfybP6kpFnMeta || {},
    alias: _91uuid_93CfybP6kpFnMeta?.alias || [],
    redirect: _91uuid_93CfybP6kpFnMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/ml/[uuid].vue").then(m => m.default || m)
  },
  {
    name: indexE6AzBtaj0XMeta?.name ?? "notes-id",
    path: indexE6AzBtaj0XMeta?.path ?? "/notes/:id()",
    meta: indexE6AzBtaj0XMeta || {},
    alias: indexE6AzBtaj0XMeta?.alias || [],
    redirect: indexE6AzBtaj0XMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/notes/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOdmscGlP5TMeta?.name ?? undefined,
    path: indexOdmscGlP5TMeta?.path ?? "/notes",
    meta: indexOdmscGlP5TMeta || {},
    alias: indexOdmscGlP5TMeta?.alias || [],
    redirect: indexOdmscGlP5TMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/notes/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexU71Ep9v4NNMeta?.name ?? "notes-index",
    path: indexU71Ep9v4NNMeta?.path ?? "",
    meta: indexU71Ep9v4NNMeta || {},
    alias: indexU71Ep9v4NNMeta?.alias || [],
    redirect: indexU71Ep9v4NNMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/notes/index/index.vue").then(m => m.default || m)
  },
  {
    name: labelsJ0D3KYifpNMeta?.name ?? "notes-index-labels",
    path: labelsJ0D3KYifpNMeta?.path ?? "labels",
    meta: labelsJ0D3KYifpNMeta || {},
    alias: labelsJ0D3KYifpNMeta?.alias || [],
    redirect: labelsJ0D3KYifpNMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/notes/index/labels.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index0xV2gE3xAGMeta?.name ?? "notes-labels-id",
    path: index0xV2gE3xAGMeta?.path ?? "/notes/labels/:id()",
    meta: index0xV2gE3xAGMeta || {},
    alias: index0xV2gE3xAGMeta?.alias || [],
    redirect: index0xV2gE3xAGMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/notes/labels/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexuoKHbnPpzlMeta?.name ?? undefined,
    path: indexuoKHbnPpzlMeta?.path ?? "/people/:id()",
    meta: indexuoKHbnPpzlMeta || {},
    alias: indexuoKHbnPpzlMeta?.alias || [],
    redirect: indexuoKHbnPpzlMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/people/[id]/index.vue").then(m => m.default || m),
    children: [
  {
    name: detailso9iTha7qpHMeta?.name ?? "people-id-index-details",
    path: detailso9iTha7qpHMeta?.path ?? "details",
    meta: detailso9iTha7qpHMeta || {},
    alias: detailso9iTha7qpHMeta?.alias || [],
    redirect: detailso9iTha7qpHMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/people/[id]/index/details.vue").then(m => m.default || m)
  },
  {
    name: indexrmCFejDOqdMeta?.name ?? "people-id-index",
    path: indexrmCFejDOqdMeta?.path ?? "",
    meta: indexrmCFejDOqdMeta || {},
    alias: indexrmCFejDOqdMeta?.alias || [],
    redirect: indexrmCFejDOqdMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/people/[id]/index/index.vue").then(m => m.default || m)
  },
  {
    name: insights273pTrnt9qMeta?.name ?? "people-id-index-insights",
    path: insights273pTrnt9qMeta?.path ?? "insights",
    meta: insights273pTrnt9qMeta || {},
    alias: insights273pTrnt9qMeta?.alias || [],
    redirect: insights273pTrnt9qMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/people/[id]/index/insights.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexeBGQ4mGeeBMeta?.name ?? undefined,
    path: indexeBGQ4mGeeBMeta?.path ?? "/people/groups/:id()",
    meta: indexeBGQ4mGeeBMeta || {},
    alias: indexeBGQ4mGeeBMeta?.alias || [],
    redirect: indexeBGQ4mGeeBMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/people/groups/[id]/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexHRXgLnIHq2Meta?.name ?? "people-groups-id-index",
    path: indexHRXgLnIHq2Meta?.path ?? "",
    meta: indexHRXgLnIHq2Meta || {},
    alias: indexHRXgLnIHq2Meta?.alias || [],
    redirect: indexHRXgLnIHq2Meta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/people/groups/[id]/index/index.vue").then(m => m.default || m)
  },
  {
    name: infoGnu9sROnz2Meta?.name ?? "people-groups-id-index-info",
    path: infoGnu9sROnz2Meta?.path ?? "info",
    meta: infoGnu9sROnz2Meta || {},
    alias: infoGnu9sROnz2Meta?.alias || [],
    redirect: infoGnu9sROnz2Meta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/people/groups/[id]/index/info.vue").then(m => m.default || m)
  },
  {
    name: insightsYsM2lwh88yMeta?.name ?? "people-groups-id-index-insights",
    path: insightsYsM2lwh88yMeta?.path ?? "insights",
    meta: insightsYsM2lwh88yMeta || {},
    alias: insightsYsM2lwh88yMeta?.alias || [],
    redirect: insightsYsM2lwh88yMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/people/groups/[id]/index/insights.vue").then(m => m.default || m)
  },
  {
    name: people5MZiJKm0KyMeta?.name ?? "people-groups-id-index-people",
    path: people5MZiJKm0KyMeta?.path ?? "people",
    meta: people5MZiJKm0KyMeta || {},
    alias: people5MZiJKm0KyMeta?.alias || [],
    redirect: people5MZiJKm0KyMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/people/groups/[id]/index/people.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index5xQmma36ikMeta?.name ?? undefined,
    path: index5xQmma36ikMeta?.path ?? "/people",
    meta: index5xQmma36ikMeta || {},
    alias: index5xQmma36ikMeta?.alias || [],
    redirect: index5xQmma36ikMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/people/index.vue").then(m => m.default || m),
    children: [
  {
    name: groupsbmrazhjHQRMeta?.name ?? "people-index-groups",
    path: groupsbmrazhjHQRMeta?.path ?? "groups",
    meta: groupsbmrazhjHQRMeta || {},
    alias: groupsbmrazhjHQRMeta?.alias || [],
    redirect: groupsbmrazhjHQRMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/people/index/groups.vue").then(m => m.default || m)
  },
  {
    name: indexYD0Mm6uZh8Meta?.name ?? "people-index",
    path: indexYD0Mm6uZh8Meta?.path ?? "",
    meta: indexYD0Mm6uZh8Meta || {},
    alias: indexYD0Mm6uZh8Meta?.alias || [],
    redirect: indexYD0Mm6uZh8Meta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/people/index/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexJ60w8HKmAeMeta?.name ?? "reflect",
    path: indexJ60w8HKmAeMeta?.path ?? "/reflect",
    meta: indexJ60w8HKmAeMeta || {},
    alias: indexJ60w8HKmAeMeta?.alias || [],
    redirect: indexJ60w8HKmAeMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/reflect/index.vue").then(m => m.default || m)
  },
  {
    name: registerPqoBbWbUPMMeta?.name ?? undefined,
    path: registerPqoBbWbUPMMeta?.path ?? "/register",
    meta: registerPqoBbWbUPMMeta || {},
    alias: registerPqoBbWbUPMMeta?.alias || [],
    redirect: registerPqoBbWbUPMMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/register.vue").then(m => m.default || m),
    children: [
  {
    name: emailShZ2UF7WDNMeta?.name ?? "register-email",
    path: emailShZ2UF7WDNMeta?.path ?? "email",
    meta: emailShZ2UF7WDNMeta || {},
    alias: emailShZ2UF7WDNMeta?.alias || [],
    redirect: emailShZ2UF7WDNMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/register/email.vue").then(m => m.default || m)
  },
  {
    name: indexcHX0mFVYDUMeta?.name ?? "register",
    path: indexcHX0mFVYDUMeta?.path ?? "",
    meta: indexcHX0mFVYDUMeta || {},
    alias: indexcHX0mFVYDUMeta?.alias || [],
    redirect: indexcHX0mFVYDUMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/register/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: reset_45passwordMXIqPcKr6eMeta?.name ?? "reset-password",
    path: reset_45passwordMXIqPcKr6eMeta?.path ?? "/reset-password",
    meta: reset_45passwordMXIqPcKr6eMeta || {},
    alias: reset_45passwordMXIqPcKr6eMeta?.alias || [],
    redirect: reset_45passwordMXIqPcKr6eMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: settingsd8hDdB3JZGMeta?.name ?? "settings",
    path: settingsd8hDdB3JZGMeta?.path ?? "/settings",
    meta: settingsd8hDdB3JZGMeta || {},
    alias: settingsd8hDdB3JZGMeta?.alias || [],
    redirect: settingsd8hDdB3JZGMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: styleboardQwZsZ3uSq7Meta?.name ?? "styleboard",
    path: styleboardQwZsZ3uSq7Meta?.path ?? "/styleboard",
    meta: styleboardQwZsZ3uSq7Meta || {},
    alias: styleboardQwZsZ3uSq7Meta?.alias || [],
    redirect: styleboardQwZsZ3uSq7Meta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/styleboard.vue").then(m => m.default || m)
  },
  {
    name: indexQEmLQ4rnVaMeta?.name ?? undefined,
    path: indexQEmLQ4rnVaMeta?.path ?? "/tags/:id()",
    meta: indexQEmLQ4rnVaMeta || {},
    alias: indexQEmLQ4rnVaMeta?.alias || [],
    redirect: indexQEmLQ4rnVaMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/tags/[id]/index.vue").then(m => m.default || m),
    children: [
  {
    name: detailsO29i7tnby2Meta?.name ?? "tags-id-index-details",
    path: detailsO29i7tnby2Meta?.path ?? "details",
    meta: detailsO29i7tnby2Meta || {},
    alias: detailsO29i7tnby2Meta?.alias || [],
    redirect: detailsO29i7tnby2Meta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/tags/[id]/index/details.vue").then(m => m.default || m)
  },
  {
    name: indexIU3rHm67uFMeta?.name ?? "tags-id-index",
    path: indexIU3rHm67uFMeta?.path ?? "",
    meta: indexIU3rHm67uFMeta || {},
    alias: indexIU3rHm67uFMeta?.alias || [],
    redirect: indexIU3rHm67uFMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/tags/[id]/index/index.vue").then(m => m.default || m)
  },
  {
    name: insights72QjCDsawKMeta?.name ?? "tags-id-index-insights",
    path: insights72QjCDsawKMeta?.path ?? "insights",
    meta: insights72QjCDsawKMeta || {},
    alias: insights72QjCDsawKMeta?.alias || [],
    redirect: insights72QjCDsawKMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/tags/[id]/index/insights.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexckvTUHiSr1Meta?.name ?? undefined,
    path: indexckvTUHiSr1Meta?.path ?? "/tags/groups/:id()",
    meta: indexckvTUHiSr1Meta || {},
    alias: indexckvTUHiSr1Meta?.alias || [],
    redirect: indexckvTUHiSr1Meta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/tags/groups/[id]/index.vue").then(m => m.default || m),
    children: [
  {
    name: index8ddSRRdOewMeta?.name ?? "tags-groups-id-index",
    path: index8ddSRRdOewMeta?.path ?? "",
    meta: index8ddSRRdOewMeta || {},
    alias: index8ddSRRdOewMeta?.alias || [],
    redirect: index8ddSRRdOewMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/tags/groups/[id]/index/index.vue").then(m => m.default || m)
  },
  {
    name: infoLcqey3fPgOMeta?.name ?? "tags-groups-id-index-info",
    path: infoLcqey3fPgOMeta?.path ?? "info",
    meta: infoLcqey3fPgOMeta || {},
    alias: infoLcqey3fPgOMeta?.alias || [],
    redirect: infoLcqey3fPgOMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/tags/groups/[id]/index/info.vue").then(m => m.default || m)
  },
  {
    name: insightsOKDI2DHNVdMeta?.name ?? "tags-groups-id-index-insights",
    path: insightsOKDI2DHNVdMeta?.path ?? "insights",
    meta: insightsOKDI2DHNVdMeta || {},
    alias: insightsOKDI2DHNVdMeta?.alias || [],
    redirect: insightsOKDI2DHNVdMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/tags/groups/[id]/index/insights.vue").then(m => m.default || m)
  },
  {
    name: tagsKrA06bEXLZMeta?.name ?? "tags-groups-id-index-tags",
    path: tagsKrA06bEXLZMeta?.path ?? "tags",
    meta: tagsKrA06bEXLZMeta || {},
    alias: tagsKrA06bEXLZMeta?.alias || [],
    redirect: tagsKrA06bEXLZMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/tags/groups/[id]/index/tags.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexAt7ywSjimPMeta?.name ?? undefined,
    path: indexAt7ywSjimPMeta?.path ?? "/tags",
    meta: indexAt7ywSjimPMeta || {},
    alias: indexAt7ywSjimPMeta?.alias || [],
    redirect: indexAt7ywSjimPMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/tags/index.vue").then(m => m.default || m),
    children: [
  {
    name: groups1rMHIIxXDHMeta?.name ?? "tags-index-groups",
    path: groups1rMHIIxXDHMeta?.path ?? "groups",
    meta: groups1rMHIIxXDHMeta || {},
    alias: groups1rMHIIxXDHMeta?.alias || [],
    redirect: groups1rMHIIxXDHMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/tags/index/groups.vue").then(m => m.default || m)
  },
  {
    name: indexLxbpZUGw1xMeta?.name ?? "tags-index",
    path: indexLxbpZUGw1xMeta?.path ?? "",
    meta: indexLxbpZUGw1xMeta || {},
    alias: indexLxbpZUGw1xMeta?.alias || [],
    redirect: indexLxbpZUGw1xMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/tags/index/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: updates6g3D2w7v6IMeta?.name ?? "updates",
    path: updates6g3D2w7v6IMeta?.path ?? "/updates",
    meta: updates6g3D2w7v6IMeta || {},
    alias: updates6g3D2w7v6IMeta?.alias || [],
    redirect: updates6g3D2w7v6IMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/updates.vue").then(m => m.default || m)
  },
  {
    name: websocket_45testbzGvR3b04tMeta?.name ?? "websocket-test",
    path: websocket_45testbzGvR3b04tMeta?.path ?? "/websocket-test",
    meta: websocket_45testbzGvR3b04tMeta || {},
    alias: websocket_45testbzGvR3b04tMeta?.alias || [],
    redirect: websocket_45testbzGvR3b04tMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/websocket-test.vue").then(m => m.default || m)
  },
  {
    name: indexTcPPPZe8ROMeta?.name ?? "wisdom-id",
    path: indexTcPPPZe8ROMeta?.path ?? "/wisdom/:id()",
    meta: indexTcPPPZe8ROMeta || {},
    alias: indexTcPPPZe8ROMeta?.alias || [],
    redirect: indexTcPPPZe8ROMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/wisdom/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexriWJ95Yh4AMeta?.name ?? "wisdom",
    path: indexriWJ95Yh4AMeta?.path ?? "/wisdom",
    meta: indexriWJ95Yh4AMeta || {},
    alias: indexriWJ95Yh4AMeta?.alias || [],
    redirect: indexriWJ95Yh4AMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/wisdom/index.vue").then(m => m.default || m)
  },
  {
    name: index7tYRxj1oRwMeta?.name ?? "write",
    path: index7tYRxj1oRwMeta?.path ?? "/write",
    meta: index7tYRxj1oRwMeta || {},
    alias: index7tYRxj1oRwMeta?.alias || [],
    redirect: index7tYRxj1oRwMeta?.redirect,
    component: () => import("/webapps/journalistic-pwa/frontend/pages/write/index.vue").then(m => m.default || m)
  }
]