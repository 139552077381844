import { v4, v5 } from 'uuid'
import { reEmail, rePersonNew, reTagNew } from './patterns'

export const uuid4 = v4
export const uuid5 = v5

export enum Modules {
  JOURNAL = 'journal',
  DREAMS = 'dreams',
  TAGS = 'tags',
  PEOPLE = 'people',
  NOTES = 'notes',
  FOCUS = 'focus',
  GEMS = 'gems',
  IDEAS = 'ideas',
  TODOS = 'todos',
}

export type Module = `${Modules}`

export enum Models {
  ENTRY = 'Entry',
  DREAM = 'Dream',
  BULLET = 'Bullet',
  TAG = 'Tag',
  TAG_GROUP = 'TagGroup',
  PERSON = 'Person',
  PERSON_GROUP = 'PersonGroup',
  NOTE = 'Note',
  NOTE_LABEL = 'NoteLabel',
  GOAL = 'Goal',
  GEM = 'Gem',
  IDEA = 'Idea',
  TODO = 'Todo',
}

export function dedupeList(list: (number | string)[]) {
  return Array.from(new Set(list))
}

export type Model = `${Models}`

export const modelPluralMap: Partial<Record<Model, string>> = {
  Entry: 'Entries',
  Person: 'People',
}

export function getModelNamePlural(model: Model): string {
  return modelPluralMap[model] || `${model}s`
}

export function getModelDictName(model: Model): string {
  const modelNamePlural = getModelNamePlural(model)
  return `${modelNamePlural.charAt(0).toLowerCase()}${modelNamePlural.slice(1)}Dict`
}

export function listMapper<T extends { id: string | number }>(list: T[] | undefined): { [id: number | string]: T } {
  if (list) {
    return list.reduce((dict, item: T) => {
      dict[item.id] = item
      return dict
    }, {} as { [id: number | string]: T })
  }
  return {}
}

export function listMapperDynamic<T extends { id: string | number }>(list: T[], getKeyFunc: (item: T) => string): { [id: number | string]: T } {
  if (Array.isArray(list)) {
    return list.reduce((dict, item) => {
      dict[getKeyFunc(item)] = item
      return dict
    }, {} as { [id: number | string]: T })
  }
  return {}
}

/**
 * Move element in array from one position to another.
 * @param {[any]} a - Any array.
 * @param {Integer} oldIdx - Original index of element that should be moved.
 * @param {Integer} newIdx - New index of element that should be moved.
 */
export function moveElement(a: any[], oldIdx: number, newIdx: number): void {
  if (newIdx === oldIdx)
    return
  if (newIdx >= a.length) {
    let k = newIdx - a.length + 1
    while (k--) {
      a.push(undefined)
    }
  }
  a.splice(newIdx, 0, a.splice(oldIdx, 1)[0])
}

/** Validate email format. */
export function validEmail(email: string): boolean {
  return reEmail.test(email)
}

/** Returns word count of any text. */
export function getWordCount(text: string): number {
  return text.split(/\s+/).length
}

/**
 * Find tags- and people in text, generate their unique IDs and return them
 * without duplicates.
 * @param text - text to parse.
 * @param namespace - Profile UID.
 * @returns - lists of found tags and people.
 */
export function parseText(
  text: string,
  namespace: ProfileUid,
): {
    tags: { id: TagId, name: string }[]
    people: { id: PersonId, name: string }[]
  } {
  const tags: { [id: TagId]: { id: TagId, name: string } } = {}
  const people: { [id: PersonId]: { id: PersonId, name: string } } = {}
  text.match(reTagNew)?.forEach((tag) => {
    const name = tag.slice(1)
    const id = uuid5(name.toLowerCase(), namespace)
    if (!tags[id]) {
      tags[id] = { id, name }
    }
  })
  text.match(rePersonNew)?.forEach((person) => {
    const name = person.slice(1)
    const id = uuid5(name.toLowerCase(), namespace)
    if (!people[id]) {
      people[id] = { id, name }
    }
  })
  return {
    tags: Object.values(tags),
    people: Object.values(people),
  }
}

/**
 * Get absolute y position of caret in px.
 * Time: ~2.5ms on a 2018 MacBook Pro.
 * Inpired by: https://medium.com/@jh3y/how-to-where-s-the-caret-getting-the-xy-position-of-the-caret-a24ba372990a
 * @param {object} element - the textarea element to obtain coordinates for
 */
export function getCaretXY(element: HTMLTextAreaElement): { x: number, top: number, bottom: number } {
  const { top: textElementTop, left: textElementLeft } = element.getBoundingClientRect()
  const selectionPoint = element.selectionEnd
  // Create a dummy element, copy style and populate with content up to the caret.
  const dummy = document.createElement('div')
  const computedStyle = getComputedStyle(element)
  for (const prop of computedStyle) {
    dummy.style[prop as any] = computedStyle[prop as any]
  }
  dummy.textContent = element.value.substring(0, selectionPoint)
  dummy.style.position = 'relative' // this is important to get the relative position of the div.
  dummy.style.height = 'auto'
  // Add a marker element to obtain relative caret position.
  const span = document.createElement('span')
  span.textContent = ''
  dummy.appendChild(span)
  document.body.appendChild(dummy)
  const {
    offsetLeft: caretElementOffLeft,
    offsetTop: caretElementOffTop,
    offsetHeight: caretElementHeight,
  } = span
  // Remove the dummy element.
  document.body.removeChild(dummy)
  return {
    x: textElementLeft + caretElementOffLeft, // Width is 0px.
    top: textElementTop + caretElementOffTop,
    bottom: textElementTop + caretElementOffTop + caretElementHeight,
  }
}

/**
 * Round a number
 * @param {float} number - some number
 * @param {integer} digits - number of decimal places
 */
export function round(number: number, digits: number) {
  return number.toFixed(digits)
}
/**
 * Takes a date string and converts it into a nice format.
 * @param {string} dateStr - some date string [yyyy-MM-dd].
 */
export function dateDisplay(dateStr: DateString) {
  return getDateDisplay(dateStr)
}
/**
 * Takes a date string and displays its time in a nice format.
 * @param {string} datetimeStr - some datetime string.
 */
export function timeDisplay(datetimeStr: DateTimeString) {
  return getTimeDisplay(datetimeStr)
}
/**
 * Spits out formatted string for date: MMM d[ yyyy].
 * @param {Date} date - some date.
 */
export function dateStr(date: Date | undefined, undefinedValue = 'N/A') {
  return date ? getFormattedDate(date) : undefinedValue
}
/**
 * Spits out formatted time for date.
 * @param {Date} date - some date.
 */
export function timeStr(date: Date, undefinedValue: string) {
  return date ? getFormattedTime(date) : undefinedValue
}
/**
 * Spits out number of days since date.
 * @param {Date} date - some date.
 */
export function daysAgo(date: Date | undefined) {
  return getDaysAgo(date)
}
/**
 * Capitalizes string.
 */
export function capitalize(str?: string) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : ''
}
